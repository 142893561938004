import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export const NotFoundPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Not found | Eluve</title>
      </Helmet>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-brand-12">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-brandGray900 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-contentSecondary">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-brand-10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-9 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple"
            >
              Go back home
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};
