import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { AgendaTimelineItem, AppointmentStatusLabel } from '@eluve/blocks';
import { Box } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { useShowAppointmentsOwner } from '../../../settings/hooks/useShowAppointmentsOwner';
import { useUserName } from '../../hooks/useUserName';

interface AppointmentAgendaTimelineItemProps {
  appointmentId: string;
}

export const appointmentTimelineInfoFragment = graphql(`
  fragment AppointmentInfo on Appointments @_unmask {
    __typename
    id
    startDate
    endDate
    name
    status
    userId
    patient {
      __typename
      id
      firstName
      lastName
    }
    session_type {
      __typename
      id
      name
    }
    humanOutputs(
      where: { output: { outputType: { _eq: SOAP_NOTE } } }
      limit: 1
    ) {
      __typename
      humanOutputId
    }
    doctor_interaction {
      __typename
      appointmentId
      noteSignedAt
    }
  }
`);

const AppointmentNameAndType: React.FC<{
  name: string | undefined;
  sessionType: string | null | undefined;
}> = ({ name, sessionType = '' }) => {
  return (
    <Box vStack className="gap-0.5">
      {Boolean(sessionType && sessionType !== name) && (
        <p className="text-sm font-light text-gray-12">{sessionType}</p>
      )}
      <p className="text-sm font-light text-gray-11">{name ?? ''}</p>
    </Box>
  );
};

const AppointmentOwner: React.FC<{ userId: string }> = ({ userId }) => {
  const userName = useUserName(userId);
  return <span className="text-xs">{userName}</span>;
};

export const AppointmentAgendaTimelineItem: React.FC<
  AppointmentAgendaTimelineItemProps
> = ({ appointmentId }) => {
  const appointment = useCompleteFragment({
    fragment: appointmentTimelineInfoFragment,
    key: {
      id: appointmentId,
    },
  });
  const [showAppointmentsOwner] = useShowAppointmentsOwner();

  const startTime = new Date(appointment.startDate);
  const endTime = appointment.endDate
    ? new Date(appointment.endDate)
    : dayjs(startTime).add(1, 'hour').toDate();
  const isSigned = Boolean(appointment.doctor_interaction?.noteSignedAt);
  const isSummarized = Boolean(appointment.humanOutputs?.length);
  const patient = appointment.patient;

  return (
    <AgendaTimelineItem startTime={startTime} endTime={endTime} asChild>
      <Link
        to={`../appointments/${appointmentId}`}
        className="min-w-[240px] gap-2"
      >
        <Box vStack>
          <Box spaceBetween className="w-full flex-wrap-reverse gap-2">
            <span className="text-sm font-semibold text-gray-11 privacy-text">
              {patient
                ? `${patient.firstName} ${patient.lastName}`
                : 'Patient Not Specified'}
            </span>
            <p className="min-w-min text-sm font-light text-gray-11">
              {dayjs(startTime).format('h:mm a')} -{' '}
              {dayjs(endTime).format('h:mm a')}
            </p>
          </Box>
          <Box spaceBetween className="w-full flex-wrap items-start gap-1.5">
            <AppointmentNameAndType
              name={appointment.name}
              sessionType={appointment.session_type?.name}
            />
            <Box hStack className="min-w-min flex-wrap">
              <AppointmentStatusLabel
                isSigned={isSigned}
                isSummarized={isSummarized}
                status={appointment.status}
                size="xs"
              />
            </Box>
          </Box>
          {showAppointmentsOwner && appointment.userId && (
            <AppointmentOwner userId={appointment.userId} />
          )}
        </Box>
      </Link>
    </AgendaTimelineItem>
  );
};
