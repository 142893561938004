import React from 'react';

import { SparklesCore } from '@eluve/components';

export interface GeneratingOutputProps {
  particleColor?: string;
}

export const GeneratingOutput: React.FC<GeneratingOutputProps> = ({
  particleColor,
}) => {
  return (
    <div className="flex h-96 w-full flex-col items-center justify-start overflow-hidden rounded-md">
      <h3 className="relative z-20 text-center text-3xl font-bold">
        Generating Output
      </h3>

      <div className="relative h-40 w-full px-2">
        {/* Gradients */}
        <div className="absolute inset-x-20 top-0 h-[2px] w-3/4 bg-gradient-to-r from-transparent via-brand-8 to-transparent blur-sm" />
        <div className="absolute inset-x-20 top-0 h-px w-3/4 bg-gradient-to-r from-transparent via-brand-8 to-transparent" />
        <div className="absolute inset-x-60 top-0 h-[5px] w-1/4 bg-gradient-to-r from-transparent via-brand-10 to-transparent blur-sm" />
        <div className="absolute inset-x-60 top-0 h-px w-1/4 bg-gradient-to-r from-transparent via-brand-10 to-transparent" />

        {/* Core component */}
        <SparklesCore
          background="transparent"
          minSize={1.0}
          maxSize={1.4}
          particleDensity={600}
          className="h-full w-full"
          particleColor={particleColor}
        />

        {/* Radial Gradient to prevent sharp edges */}
        <div className="absolute inset-0 h-full w-full [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
      </div>
    </div>
  );
};
