import { useMemo } from 'react';

import { toSentences } from '@eluve/utils';

import { useTranscriptionMachineSelector } from '../TranscriptionMachineProvider';

export const useInSessionTranscript = () => {
  const {
    transcript,
    interimTranscript,
    isTranscriptionDegraded,
    recordingStartedAt,
  } = useTranscriptionMachineSelector((s) => {
    const {
      transcript,
      interimTranscript,
      isTranscriptionDegraded,
      sessionAppointmentId,
      recordingStartedAt,
    } = s.context;

    return {
      transcript,
      interimTranscript,
      isTranscriptionDegraded,
      sessionAppointmentId,
      recordingStartedAt,
    };
  });

  const transcriptSentences = useMemo(
    () => toSentences(transcript ?? ''),
    [transcript],
  );

  const interimTranscriptSentences = useMemo(() => {
    if (!interimTranscript) return;
    return toSentences(interimTranscript);
  }, [interimTranscript]);

  return {
    transcript: transcriptSentences,
    interimTranscript: interimTranscriptSentences,
    isTranscriptionDegraded,
    recordingStartedAt,
  };
};
