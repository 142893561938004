import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  H4,
  Textarea,
  Toggle,
} from '@eluve/components';
import { FeedbackTypeEnum } from '@eluve/graphql-types';

import { FeedbackLogoThumbsDown } from './FeedbackLogoThumbsDown';
import { FeedbackLogoThumbsUp } from './FeedbackLogoThumbsUp';
import { RatingSelector } from './RatingSelector';

export type Tag = {
  id: number;
  name: string;
  isTagged: boolean;
};

export interface FeedbackModalProps {
  feedbackType: FeedbackTypeEnum | null;
  tags: Tag[];
  onFeedbackClick: (type: FeedbackTypeEnum) => Promise<void>;
  onTagPressedChange: (tagId: number, isTagged: boolean) => Promise<void>;
  onAdditionalFeedbackSubmit: (
    type: FeedbackTypeEnum | null,
    comment: string,
    tags: Tag[],
    rating: number | null,
  ) => Promise<void>;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  tags,
  feedbackType,
  onFeedbackClick,
  onTagPressedChange,
  onAdditionalFeedbackSubmit,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [feedbackComment, setFeedbackComment] = useState<string>('');
  const [rating, setRating] = useState<number | null>(null);

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const toggleDialog = (open: boolean) => {
    setIsDialogOpen(open);
  };

  const handleFeedbackClick = async (type: FeedbackTypeEnum) => {
    // only allow one feedback per instance? -- this is what chat gpt does
    if (feedbackType !== null) {
      return;
    }

    setIsDialogOpen(true);

    await onFeedbackClick(type);
  };

  const handleAdditionalFeedbackSubmit = async () => {
    await onAdditionalFeedbackSubmit(
      feedbackType,
      feedbackComment,
      tags,
      rating,
    );

    setIsDialogOpen(false);
  };

  const isPositiveFeedback = feedbackType === 'POSITIVE';

  return (
    <Dialog open={isDialogOpen} onOpenChange={toggleDialog}>
      <div className="flex flex-row gap-6">
        <Button
          size={'xs'}
          className="bg-brandGray100"
          disabled={feedbackType !== null}
          variant="outlinesecondary"
          onClick={() => handleFeedbackClick('POSITIVE')}
        >
          Yes
        </Button>
        <Button
          size={'xs'}
          className="bg-brandGray100"
          disabled={feedbackType !== null}
          variant="outlinesecondary"
          onClick={() => handleFeedbackClick('NEGATIVE')}
        >
          No
        </Button>
      </div>

      <DialogContent className="flex flex-col items-center justify-center gap-3 p-4 sm:max-w-[700px] sm:gap-10 sm:p-10">
        {isPositiveFeedback ? (
          <FeedbackLogoThumbsUp />
        ) : (
          <FeedbackLogoThumbsDown />
        )}

        <H2> Any additional feedback? </H2>

        <div className="flex w-full flex-col gap-14">
          <div className="flex flex-col gap-3">
            <H4>How would you rate your Eluve experience for this session?</H4>

            <RatingSelector
              onRatingSelected={(value) => setRating(value)}
              labelFor0="Poor"
              labelFor5="Okay"
              labelFor10="Excellent"
            />
          </div>
          <div className="flex w-full flex-col gap-3">
            <Textarea
              className="h-[100px] w-full resize-y text-gray-12 outline-none focus-visible:ring-0"
              value={feedbackComment}
              placeholder="Tell us more!"
              onChange={(e) => setFeedbackComment(e.target.value)}
            />

            <div>
              {tags.map((tag) => (
                <Toggle
                  key={tag.id}
                  variant="outline"
                  className="my-0.5 mr-2 h-8 rounded-3xl text-xs text-gray-12 hover:border-brand-4 hover:bg-brand-4 hover:text-brand-11 data-[state=on]:border-brand-4 data-[state=on]:bg-brand-4 data-[state=on]:text-brand-11 sm:text-sm"
                  pressed={tag.isTagged}
                  onPressedChange={(isTagged) => {
                    onTagPressedChange(tag.id, isTagged);
                  }}
                >
                  {capitalizeFirstLetter(tag.name)}
                </Toggle>
              ))}
            </div>
          </div>
        </div>

        <DialogFooter className="flex w-full flex-col items-end">
          <Button onClick={handleAdditionalFeedbackSubmit}>
            Submit Feedback
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
