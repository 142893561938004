import { initContract } from '@ts-rest/core';

import { adminAppointmentContract } from './admin-appointment.contract';
import { adminLlmContract } from './admin-llm.contract';
import { appointmentsContract } from './appointments.contract';
import { authContract } from './auth.contract';
import { chartsContract } from './charts.contract';
import { evalContract } from './eval.contract';
import { gcsContract } from './gcs.contract';
import { humanOutputsContract } from './human-outputs.contract';
import { llmOutputsContract } from './llm-outputs.contract';
import { llmContract } from './llm.contract';
import { locationsContract } from './locations.contract';
import { patientsContract } from './patients.contract';
import { promptsContract } from './prompt.contract';
import { transcriptionContract } from './transcription.contract';
import { waitlistContract } from './waitlist.contract';

const c = initContract();

export const apiContract = c.router(
  {
    appointments: appointmentsContract,
    adminAppointment: adminAppointmentContract,
    auth: authContract,
    gcs: gcsContract,
    llm: llmContract,
    patients: patientsContract,
    waitlist: waitlistContract,
    prompts: promptsContract,
    eval: evalContract,
    charts: chartsContract,
    locations: locationsContract,
    humanOutputs: humanOutputsContract,
    llmOutputs: llmOutputsContract,
    transcription: transcriptionContract,
    adminLlm: adminLlmContract,
  },
  {
    pathPrefix: '/api',
  },
);
