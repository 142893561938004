import { Info, MinusCircle, Pencil, PlusCircle, Trash } from 'lucide-react';
import React from 'react';
import { match } from 'ts-pattern';

import { TooltipLabel } from '@eluve/blocks';
import { Button, H3, H4 } from '@eluve/components';
import { differentialDiagnosesSchema } from '@eluve/llm-outputs';

import { OutputParsingError } from './OutputParsingError';
import { OutputTitle } from './OutputTitle';

export interface DifferentialDiagnosesOutputProps {
  content: Record<string, unknown> | Record<string, unknown>[];
}

export const DifferentialDiagnosesOutput: React.FC<
  DifferentialDiagnosesOutputProps
> = ({ content }) => {
  const diagnoses = differentialDiagnosesSchema.safeParse(content);

  if (!diagnoses.success) {
    return <OutputParsingError />;
  }

  return (
    <div className="space-y-4">
      <OutputTitle title="Differential Diagnoses" />
      {diagnoses.data.map((diagnosis) => (
        <div key={diagnosis.diagnosis} className="space-y-2">
          <div className="border p-4 shadow-md">
            <div className="flex items-start justify-between gap-6">
              <div className="flex items-center gap-2">
                <H3>{diagnosis.diagnosis}</H3>
                {diagnosis.description && (
                  <TooltipLabel label={diagnosis.description}>
                    <Info className="size-6" />
                  </TooltipLabel>
                )}
              </div>
              <div className="flex items-center gap-1">
                <TooltipLabel label="Edit this diagnosis">
                  <Button variant="outline" size="smallIcon">
                    <Pencil className="size-5" />
                  </Button>
                </TooltipLabel>

                <TooltipLabel label="Remove this diagnosis">
                  <Button variant="outline" size="smallIcon">
                    <Trash className="size-5" />
                  </Button>
                </TooltipLabel>
              </div>
            </div>
            <div className="mt-4 grid grid-cols-[min-content_1fr] gap-2">
              <div>
                <PlusCircle />
              </div>
              <div>
                <H4 m="mb-1">Positive Indicators</H4>
                <ul className="list-inside list-disc">
                  {diagnosis.positiveIndicators.map((indicator) => (
                    <li key={indicator}>{indicator}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mt-4 grid grid-cols-[min-content_1fr] gap-2">
              <div>
                <MinusCircle />
              </div>
              <div>
                <H4 m="mb-1">Negative Indicators</H4>
                <ul className="list-inside list-disc">
                  {match(diagnosis.negativeIndicators.length)
                    .with(0, () => <li>No Negative Indicators</li>)
                    .otherwise(() => {
                      return diagnosis.negativeIndicators.map((indicator) => (
                        <li key={indicator}>{indicator}</li>
                      ));
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
