import { useSuspenseQuery } from '@apollo/client';
import max from 'lodash/max';
import React, { useMemo } from 'react';

import {
  AppointmentStatusLabel,
  ShowAllAppointmentsToggle,
  appointmentStatusAccessorFn,
  useShowAllAppointmentsToggle,
} from '@eluve/blocks';
import { ColDefBuilder, DataTable, Link } from '@eluve/components';
import { ResultOf, graphql } from '@eluve/graphql.tada';
import {
  useIsTenantAdminFromSession,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import { useIsFeatureFlagEnabled } from '@eluve/smart-blocks';
import { formatHumanName } from '@eluve/utils';

const appointmentDetailsFragment = graphql(`
  fragment AppointmentDetails on Appointments @_unmask {
    __typename
    tenantId
    id
    name
    startDate
    createdAt
    updatedAt
    status
    user {
      __typename
      id
      firstName
      lastName
    }
    transcription {
      __typename
      id
      updatedAt
    }
    patient {
      __typename
      id
      firstName
      lastName
    }
    humanOutputs(
      where: { output: { outputType: { _eq: SOAP_NOTE } } }
      limit: 1
      orderBy: { output: { updatedAt: DESC } }
    ) {
      __typename
      humanOutputId
      output {
        __typename
        id
        updatedAt
      }
    }
    doctor_interaction {
      __typename
      appointmentId
      noteSignedAt
    }
  }
`);

const getUserAppointmentsQuery = graphql(
  `
    query getUserAppointments($userId: uuid!) {
      appointments(where: { userId: { _eq: $userId } }) {
        ...AppointmentDetails
      }
    }
  `,
  [appointmentDetailsFragment],
);

const getAppointmentsQuery = graphql(
  `
    query getVisibleAppointments {
      appointments(orderBy: { updatedAt: DESC }, limit: 500) {
        ...AppointmentDetails
      }
    }
  `,
  [appointmentDetailsFragment],
);

type Row = Omit<
  ResultOf<typeof getUserAppointmentsQuery>['appointments'][number],
  'humanOutputs' | 'doctor_interaction'
> & {
  isSummarized: boolean;
  isSigned: boolean;
  lastInteractedAt: string | null;
  userName: string;
  patientId?: string;
  patientName: string | null;
  isPatientsViewEnabled: boolean;
};

const columns = new ColDefBuilder<Row>()
  .linkSortable(
    'name',
    ({ tenantId, id }) => `/tenants/${tenantId}/appointments/${id}`,
  )
  .defaultSortable('userName', 'User Name')
  .defaultSortable('patientName', {
    label: 'Patient Name',
    cellRenderer: ({
      patientName,
      isPatientsViewEnabled,
      tenantId,
      patientId,
    }) => {
      if (!isPatientsViewEnabled || !patientId) {
        return <span className="privacy-text">{patientName}</span>;
      }

      return (
        <Link
          to={`/tenants/${tenantId}/patients/${patientId}`}
          className="privacy-text"
        >
          {patientName}
        </Link>
      );
    },
  })
  .dateSortable('lastInteractedAt', 'Last Modified')
  .dateSortable('startDate', 'Start Date')
  .defaultSortable('status', {
    colOptions: {
      accessorFn: appointmentStatusAccessorFn,
    },
    cellRenderer: (row) => {
      return <AppointmentStatusLabel {...row} />;
    },
  })
  .build();

const minDateString = new Date(0).toISOString();

export const AppointmentsList: React.FC = () => {
  const userId = useUserIdFromSession();
  const isTenantAdmin = useIsTenantAdminFromSession();
  const [showAllAppointments] = useShowAllAppointmentsToggle();

  const cols = useMemo(() => {
    if (!isTenantAdmin || !showAllAppointments) {
      return columns.filter(
        (f) =>
          (f as unknown as Record<string, unknown>)?.accessorKey !== 'userName',
      );
    }

    return columns;
  }, [isTenantAdmin, showAllAppointments]);

  const {
    data: { appointments },
  } = useSuspenseQuery(
    showAllAppointments ? getAppointmentsQuery : getUserAppointmentsQuery,
    {
      variables: { userId },
    },
  );

  const isPatientsViewEnabled = useIsFeatureFlagEnabled('PATIENTS_VIEW');

  const data = useMemo(() => {
    return appointments.map<Row>((a) => {
      const { doctor_interaction, humanOutputs, ...rest } = a;

      const interactionTimestamps = [
        a.updatedAt,
        a.transcription?.updatedAt ?? minDateString,
        a.humanOutputs?.[0]?.output?.updatedAt ?? minDateString,
      ];

      const lastInteractedAt = max(interactionTimestamps) ?? minDateString;

      return {
        ...rest,
        isSummarized: humanOutputs.length > 0,
        patientId: a.patient?.id,
        userName: formatHumanName(a.user?.firstName, a.user?.lastName),
        patientName: formatHumanName(a.patient?.firstName, a.patient?.lastName),
        lastInteractedAt,
        isSigned: Boolean(doctor_interaction?.noteSignedAt),
        isPatientsViewEnabled,
      };
    });
  }, [appointments, isPatientsViewEnabled]);

  return (
    <div className="space-y-2">
      <ShowAllAppointmentsToggle />
      <DataTable
        enableGlobalSearch
        columns={cols}
        data={data}
        initialSortingState={[{ id: 'lastInteractedAt', desc: true }]}
      />
    </div>
  );
};
