import { useState } from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import { templateBuilderStore } from '@eluve/blocks';
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
  HStack,
  NewButton,
  P,
  Select,
  SelectContent,
  SelectTrigger,
  Textarea,
  VStack,
} from '@eluve/components';
import { PromptTemplateSelectItem } from '@eluve/frontend-feature-user-settings';
import {
  DynamicArtifactTemplate,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import { TestTranscripts } from '@eluve/llm-outputs';
import { DynamicArtifactRenderer } from '@eluve/smart-blocks';

import { activeDynamicOutputPromptTemplatesFragment } from './operations';

export const OutputTemplatesTester = () => {
  const [transcript, setTranscript] = useState('');
  const [selectedPromptTemplateId, setSelectedPromptTemplateId] = useState('');
  const [generatedOutput, setGeneratedOutput] = useState<
    DynamicArtifactTemplate | undefined
  >(undefined);
  const [isGenerating, setIsGenerating] = useState(false);

  const { activePromptTemplates } = useCompleteFragment({
    fragment: activeDynamicOutputPromptTemplatesFragment,
    key: QUERY_ROOT_ID,
  });

  const selectedPromptTemplate =
    activePromptTemplates?.find((t) => t.id === selectedPromptTemplateId) ??
    activePromptTemplates?.[0];

  const apiClient = useApiClient();

  const generateOutput = async () => {
    try {
      setIsGenerating(true);
      setGeneratedOutput(undefined);

      const { context } = templateBuilderStore.getSnapshot();
      const dynamicArtifactTemplate: DynamicArtifactTemplate = {
        name: context.name,
        description: context.description,
        blocks: context.blocks,
      };

      const summary = await apiClient.adminLlm.summarizeTranscriptSync({
        body: {
          transcript,
          promptTemplateId: selectedPromptTemplate?.id ?? '',
          dynamicArtifactTemplate,
        },
      });
      if (summary.status === 201) {
        const { content } = summary.body;
        const hydrated = hydrateDynamicArtifactTemplate(
          dynamicArtifactTemplate,
          (content as Record<string, unknown>) ?? {},
        );
        setGeneratedOutput(hydrated);
      } else {
        toast.error('Failed to generate output');
      }
    } catch (e) {
      toast.error('Failed to generate output');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <NewButton text="LLM Tester" type="outline" />
      </DrawerTrigger>
      <DrawerContent>
        <VStack
          gap={2}
          className="container h-[80vh] overflow-scroll"
          align="center"
        >
          <Textarea
            className="h-[170px] w-full resize-none text-gray-12 outline-none focus-visible:ring-0"
            value={transcript ?? ''}
            placeholder="Paste transcript here!"
            onChange={(e) => setTranscript(e.target.value)}
          />

          <HStack>
            <P>Example conversations: </P>
            {Object.entries(TestTranscripts).map(([key, value]) => (
              <NewButton
                key={key}
                text={key}
                size="s"
                type="outlineSubtle"
                onClick={() => {
                  setTranscript(value);
                }}
              />
            ))}
          </HStack>

          <Select
            value={selectedPromptTemplateId}
            onValueChange={setSelectedPromptTemplateId}
          >
            <SelectTrigger>
              {selectedPromptTemplate?.name ?? 'Select Prompt Template'}
            </SelectTrigger>
            <SelectContent>
              {activePromptTemplates?.map((template) => (
                <PromptTemplateSelectItem
                  key={template.id}
                  templateId={template.id ?? ''}
                  templateName={template.name ?? ''}
                  templateDescription={template.description}
                />
              ))}
            </SelectContent>
          </Select>
          <NewButton
            text={
              isGenerating
                ? 'Generating...'
                : generatedOutput
                  ? 'Regenerate Output'
                  : 'Generate Output'
            }
            type="primary"
            disabled={
              !transcript || !selectedPromptTemplate?.id || isGenerating
            }
            onClick={generateOutput}
          />
          {generatedOutput && (
            <DynamicArtifactRenderer
              isReadonly={true}
              blocks={generatedOutput.blocks}
            />
          )}
        </VStack>
      </DrawerContent>
    </Drawer>
  );
};
