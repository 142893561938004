import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import { ColDefBuilder, DataTable } from '@eluve/components';
import { AppointmentFileTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

import { AppointmentFileActionsColumn } from './AppointmentFileActionsColumn';

export const appointmentFilesFragment = graphql(`
  fragment AppointmentFiles on Appointments {
    __typename
    id
    appointmentFiles {
      __typename
      appointmentId
      fileId
      fileType
      userUpload {
        __typename
        id
        status
        fileUrl
        originalFileName
        transcript {
          __typename
          id
          segmentId
          transcriptionStatus
        }
      }
    }
  }
`);

const appointmentFilesQuery = graphql(
  `
    query appointmentFiles($tenantId: uuid!, $appointmentId: uuid!) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...AppointmentFiles
      }
    }
  `,
  [appointmentFilesFragment],
);

type Row = {
  tenantId: string;
  appointmentId: string;
  fileId: string;
  fileUrl: string | null;
  fileName: string | undefined;
  status: string;
  type: AppointmentFileTypesEnum;
  transcriptionStatus?: string;
  segmentId?: string;
};

const columns = new ColDefBuilder<Row>()
  .defaultSortable('fileId', 'Appointment File Id')
  .defaultSortable('status')
  .defaultSortable('type')
  .defaultSortable('fileName', 'File Name')
  .defaultSortable('fileUrl', 'GCS path')
  .defaultSortable('transcriptionStatus', 'Transcription Status')
  .defaultSortable('segmentId', 'Segment Id')
  .colDef({
    header: 'Actions',
    cell: ({ row: { original } }) => (
      <AppointmentFileActionsColumn
        tenantId={original.tenantId}
        appointmentId={original.appointmentId}
        fileId={original.fileId}
        filePath={original.fileUrl ?? ''}
        fileType={original.type}
      />
    ),
  })
  .build();

export const AdminAppointmentFiles: React.FC = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };

  useSuspenseQuery(appointmentFilesQuery, {
    variables: { tenantId, appointmentId },
  });

  const { appointmentFiles } = useCompleteFragment({
    fragment: appointmentFilesFragment,
    key: { id: appointmentId },
  });

  const rows = useMemo(() => {
    return appointmentFiles.map<Row>((af) => {
      return {
        tenantId,
        appointmentId,
        fileId: af.fileId,
        fileUrl: af.userUpload.fileUrl,
        fileName: af.userUpload.originalFileName ?? undefined,
        type: af.fileType,
        status: af.userUpload.status,
        transcriptionStatus:
          af.fileType === 'RAW_TRANSCRIPT_METADATA'
            ? 'N/A'
            : af.userUpload.transcript?.transcriptionStatus ?? 'NO TRANSCRIPT',
        segmentId: af.userUpload?.transcript?.segmentId,
      };
    });
  }, [appointmentFiles, tenantId, appointmentId]);

  return (
    <div>
      <DataTable
        data={rows}
        columns={columns}
        initialSortingState={[
          {
            id: 'fileName',
            desc: true,
          },
        ]}
      />
    </div>
  );
};
