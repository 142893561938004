import { useMutation } from '@apollo/client';
import { Editor } from '@tiptap/core';
import React, { useMemo, useRef } from 'react';
import { useLatest } from 'react-use';
import { toast } from 'sonner';

import { useCompleteFragment } from '@eluve/apollo-client';
import { useTypingThrottle } from '@eluve/blocks';
import { Box, Button, inputVariants } from '@eluve/components';
import { EluveEditor, EluveEditorHandle } from '@eluve/eluve-editor';
import {
  useAppointmentId,
  useAppointmentStatus,
  useIsAppointmentReadonly,
} from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

import { pendingUnsavedChangesStore } from './components/AppointmentLastSaved';

const doctorInteractionNotesFragment = graphql(`
  fragment DoctorInteractionNotes on AppointmentDoctorInteractions {
    __typename
    appointmentId
    additionalNotes
    updatedAt
  }
`);

const insertAdditionalNotesMutation = graphql(`
  mutation insertDoctorAdditionalNotes(
    $appointmentId: uuid!
    $additionalNotes: String
  ) {
    insertAppointmentDoctorInteractionsOne(
      object: {
        appointmentId: $appointmentId
        additionalNotes: $additionalNotes
      }
      onConflict: {
        constraint: appointment_doctor_interactions_pkey
        updateColumns: [additionalNotes]
      }
    ) {
      __typename
      appointmentId
      additionalNotes
      updatedAt
    }
  }
`);

const AdditionalNotesContent: React.FC = () => {
  const appointmentId = useAppointmentId();
  const [insertAdditionalNotes] = useMutation(insertAdditionalNotesMutation);

  const data = useCompleteFragment({
    fragment: doctorInteractionNotesFragment,
    key: { appointmentId },
    strict: false,
  });

  const isReadonly = useIsAppointmentReadonly();

  const additionalNotes = data?.additionalNotes ?? '';
  const latestAdditionalNotes = useLatest(additionalNotes);

  const appointmentStatus = useAppointmentStatus();

  const editorRef = useRef<EluveEditorHandle | null>(null);

  const updateNotes = useRef(async (notes: string) => {
    await insertAdditionalNotes({
      variables: {
        appointmentId,
        additionalNotes: notes,
      },
      onError: () => {
        toast.error(
          <Box spaceBetween fullWidth className="items-center">
            <span>Failed to update additional notes</span>
            <Button
              onClick={() => updateNotes.current(notes)}
              variant="destructive"
              className="float-end"
            >
              Retry
            </Button>
          </Box>,
          {
            id: 'edit-summary-error',
          },
        );
      },
    });

    pendingUnsavedChangesStore.setState(false);
  });

  const updateNotesThrottle = useTypingThrottle(updateNotes.current);

  const onUpdate = useMemo(() => {
    return async (editor: Editor | undefined) => {
      if (
        latestAdditionalNotes.current === editor?.storage.markdown.getMarkdown()
      ) {
        return;
      }
      pendingUnsavedChangesStore.setState(true);
      editor && updateNotesThrottle(editor.storage.markdown.getMarkdown());
    };
  }, [latestAdditionalNotes, updateNotesThrottle]);

  if (
    appointmentStatus === 'NOT_STARTED' ||
    appointmentStatus === 'CANCELLED'
  ) {
    return null;
  }

  return (
    <div
      className={inputVariants({ className: 'my-4 rounded-lg border-gray-5' })}
    >
      <span className="p-2 text-xs">Additional Notes</span>
      <EluveEditor
        disabled={isReadonly}
        ref={editorRef}
        content={additionalNotes}
        onUpdate={onUpdate}
        className="!px-0"
      />
    </div>
  );
};

export const AdditionalNotes = React.memo(AdditionalNotesContent);
