import { useSuspenseQuery } from '@apollo/client';
import { ThumbsDown, ThumbsUp } from 'lucide-react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import {
  ColDefBuilder,
  DataTable,
  H3,
  HStack,
  PageTitle,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const getModelPromptTemplateDetailsQuery = graphql(`
  query getModelPromptTemplateDetails($id: uuid!) {
    llmModelPromptTemplatesByPk(id: $id) {
      __typename
      id
      llm_outputs {
        __typename
        id
        feedback {
          __typename
          id
          rating
          type
          comment
          createdAt
          summaryFeedbackTags {
            __typename
            feedbackTagId
            feedbackTag {
              __typename
              id
              name
            }
          }
        }
      }
      model_args {
        __typename
        id
        modelType
        name
        args
      }
    }
  }
`);

type ModelPromptTemplateFeedbackRow = {
  type: string;
  rating: number | null;
  comment: string | null;
  tags: string[];
  createdAt: string;
};

const columns = new ColDefBuilder<ModelPromptTemplateFeedbackRow>()
  .colDef({
    accessorKey: 'type',
    header: 'Type',
    cell: ({ row }) =>
      match(row.original.type)
        .with('POSITIVE', () => <ThumbsUp className="text-green" />)
        .with('NEGATIVE', () => <ThumbsDown className="text-red-600" />)
        .otherwise(() => '--'),
  })
  .defaultSortable('rating')
  .defaultSortable('comment')
  .dateSortable('createdAt', 'Created At')
  .build();

export const ModelPromptTemplateDetailsPage: React.FC = () => {
  const { modelPromptTemplateId } = useParams() as {
    modelPromptTemplateId: string;
  };

  const {
    data: { llmModelPromptTemplatesByPk: modelPromptTemplate },
  } = useSuspenseQuery(getModelPromptTemplateDetailsQuery, {
    variables: {
      id: modelPromptTemplateId,
    },
  });

  const rows = useMemo(() => {
    const feedbacks = (modelPromptTemplate?.llm_outputs ?? []).map((output) => {
      const { feedback } = output;

      return feedback.map<ModelPromptTemplateFeedbackRow>(
        ({ rating, comment, type, createdAt, summaryFeedbackTags }) => {
          return {
            comment,
            rating,
            type: type!,
            createdAt,
            tags: summaryFeedbackTags.map((sft) => sft.feedbackTag.name),
          };
        },
      );
    });

    return feedbacks.flat();
  }, [modelPromptTemplate]);

  return (
    <>
      <HStack justify="between">
        <PageTitle
          subtitle={
            <pre>{JSON.stringify(modelPromptTemplate?.model_args?.args)}</pre>
          }
        >
          {modelPromptTemplate?.model_args?.modelType}
        </PageTitle>
      </HStack>
      <H3>Feedback</H3>
      <div className="rounded-md bg-white p-2">
        <DataTable columns={columns} data={rows} />
      </div>
    </>
  );
};
