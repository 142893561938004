import format from 'date-fns/format';
import React from 'react';

import { Divider, HStack, Tag } from '@eluve/components';

export interface TranscriptTimestampDividerProps {
  timestamp?: string;
  action?: string;
}

export const TranscriptTimestampDivider: React.FC<
  TranscriptTimestampDividerProps
> = ({ timestamp = new Date().toISOString(), action = 'paused' }) => {
  const date = new Date(timestamp);

  const label = `Session ${action} ${format(date, 'P')} at ${format(date, 'p')}`;

  return (
    <HStack gap={0}>
      <Divider className="text-borderPrimary h-[1px]" />
      <Tag
        size="s"
        color="default"
        pill
        label={label}
        className="flex-1 whitespace-nowrap"
      />
      <Divider className="text-borderPrimary h-[1px]" />
    </HStack>
  );
};
