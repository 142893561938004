import { useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import {
  AppointmentStatusLabel,
  appointmentStatusAccessorFn,
  useShowAllAppointmentsToggle,
} from '@eluve/blocks';
import { ColDefBuilder, DataTable } from '@eluve/components';
import { ResultOf, graphql } from '@eluve/graphql.tada';
import { useUserIdFromSession } from '@eluve/session-helpers';

const getPatientAppointmentsQuery = graphql(`
  query getPatientAppointments($patientId: uuid!) {
    appointments(
      where: { patientId: { _eq: $patientId } }
      orderBy: { startDate: DESC }
    ) {
      __typename
      id
      tenantId
      name
      startDate
      status
      userId
      humanOutputs(
        where: { output: { outputType: { _eq: SOAP_NOTE } } }
        limit: 1
      ) {
        __typename
        humanOutputId
      }
      doctor_interaction {
        __typename
        appointmentId
        noteSignedAt
      }
    }
  }
`);

type Row = Omit<
  ResultOf<typeof getPatientAppointmentsQuery>['appointments'][number],
  'humanOutputs' | 'doctor_interaction'
> & { isSummarized: boolean; isSigned: boolean };

const columns = new ColDefBuilder<Row>()
  .detailsLink((r) => `/tenants/${r.tenantId}/appointments/${r.id}`, 'Details')
  .defaultSortable('name')
  .dateSortable('startDate')
  .defaultSortable('status', {
    colOptions: {
      accessorFn: appointmentStatusAccessorFn,
    },
    cellRenderer: (row) => {
      return <AppointmentStatusLabel {...row} />;
    },
  })
  .build();

export interface PatientAppointmentsListProps {
  patientId: string;
}

export const PatientAppointmentsList: React.FC<
  PatientAppointmentsListProps
> = ({ patientId }) => {
  const [showAllAppointment] = useShowAllAppointmentsToggle();
  const userId = useUserIdFromSession();

  const {
    data: { appointments },
  } = useSuspenseQuery(getPatientAppointmentsQuery, {
    variables: {
      patientId,
    },
  });

  const data = useMemo(() => {
    return appointments
      .map<Row>((a) => {
        const { doctor_interaction, humanOutputs, ...rest } = a;

        return {
          ...rest,
          isSummarized: humanOutputs.length > 0,
          isSigned: Boolean(doctor_interaction?.noteSignedAt),
        };
      })
      .filter((a) => showAllAppointment || a.userId === userId);
  }, [appointments, showAllAppointment, userId]);

  return <DataTable enableGlobalSearch columns={columns} data={data} />;
};
