import * as SliderPrimitive from '@radix-ui/react-slider';
import * as React from 'react';
import { useState } from 'react';

import { tv } from './cn';
import { textStyles } from './textStyles';

const sliderStyles = tv({
  slots: {
    root: 'relative flex w-full touch-none select-none items-center',
    thumb:
      'block size-5 rounded-full border-2 border-primary bg-background text-center ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    thumbValue: `hidden ${textStyles.body({ size: 'm', weight: 'semibold' })}`,
  },
  variants: {
    showValue: {
      above: {
        root: 'pt-5',
        thumbValue: '-top-7',
      },
      below: {
        root: 'pb-6',
        thumbValue: 'top-6',
      },
      inside: {
        root: 'py-2',
        thumb: 'grid aspect-square w-fit place-content-center rounded-full p-4',
        thumbValue: 'block',
      },
    },
  },
  compoundVariants: [
    {
      showValue: ['above', 'below'],
      className: {
        thumbValue:
          'absolute left-1/2 block w-fit -translate-x-1/2 rounded-md border border-gray-10 px-2',
      },
    },
  ],
});

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    showValue?: 'above' | 'below' | 'inside';
  }
>(({ className, showValue, onValueChange, ...props }, ref) => {
  const [internalValue, setInternalValue] = useState(
    props?.defaultValue?.[0] ?? props?.value?.[0] ?? 0,
  );

  const { root, thumbValue, thumb } = sliderStyles({ showValue });

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={root({ className })}
      onValueChange={(val) => {
        setInternalValue(val?.[0] ?? 0);
        onValueChange?.(val);
      }}
      {...props}
    >
      <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-secondary">
        <SliderPrimitive.Range className="absolute h-full bg-primary" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className={thumb()}>
        <span className={thumbValue()}>{internalValue}</span>
      </SliderPrimitive.Thumb>
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
