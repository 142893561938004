import { useSuspenseQuery } from '@apollo/client';
import isNil from 'lodash/isNil';
import meanBy from 'lodash/meanBy';
import orderBy from 'lodash/orderBy';
import { CopyPlusIcon } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { TooltipLabel } from '@eluve/blocks';
import {
  Box,
  Button,
  ColDefBuilder,
  DataTable,
  Label,
  Switch,
} from '@eluve/components';

import { TogglePromptTemplateActiveAction } from './TogglePromptTemplateActiveAction';
import { getPrompTemplatesQuery } from './prompt.operations';

type PromptTemplateRow = {
  id: string;
  name: string;
  type: string;
  feedbackReceived: number;
  averageRating: number | null;
  isActive: boolean;
  isCurrentDefault: boolean;
  createdAt: string;
  hasDbTemplate: boolean;
  usersWithDefault: number;
};

const columns = new ColDefBuilder<PromptTemplateRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .defaultSortable('type')
  .defaultBoolean('isCurrentDefault', 'Is Default')
  .defaultBoolean('isActive', 'Active')
  .defaultSortable('feedbackReceived', '# Feedback')
  .defaultSortable('averageRating', {
    label: 'Average Rating',
    cellRenderer: (row) => row.averageRating?.toFixed(2) ?? '',
  })
  .dateSortable('createdAt', 'Created At')
  .defaultSortable('usersWithDefault', '# User Default')
  .colDef({
    header: 'Actions',
    cell: ({ row }) => {
      const showCloneOption =
        row.original.hasDbTemplate && row.original.isActive;

      return (
        <Box hStack gap={1}>
          <TogglePromptTemplateActiveAction
            promptTemplateId={row.original.id}
          />
          {showCloneOption && (
            <TooltipLabel
              label={`Create a new Prompt Template using '${row.original.name}' as a starting point`}
            >
              <Link to={`./clone/${row.original.id}`}>
                <Button size="icon" variant="outline">
                  <CopyPlusIcon />
                </Button>
              </Link>
            </TooltipLabel>
          )}
        </Box>
      );
    },
  })
  .build();

export const PromptTemplatesList: React.FC = () => {
  const [showInactivePrompts, setShowInactivePrompts] = useState(false);
  const { data } = useSuspenseQuery(getPrompTemplatesQuery);

  const rows = useMemo(() => {
    const innerRows = orderBy(
      (data?.promptTemplates ?? []).map<PromptTemplateRow>((p) => {
        const allFeedback = p.prompt_models
          .flatMap((pm) => pm.llm_outputs)
          .flatMap((lo) => lo.feedback);

        const averageRating = meanBy(
          allFeedback.filter((f) => !isNil(f.rating)),
          (f) => f.rating ?? 0,
        );

        return {
          id: p.id,
          name: p.name,
          type: p.outputType,
          createdAt: p.createdAt,
          feedbackReceived: allFeedback.length,
          averageRating: isNaN(averageRating) ? null : averageRating,
          isActive: p.isActive ?? false,
          isCurrentDefault: p.isCurrentDefault,
          hasDbTemplate: !isNil(p.template),
          usersWithDefault: p.userSettingsAggregate?.aggregate?.count ?? 0,
        };
      }),
      (m) => m.isCurrentDefault,
      ['desc'],
    );

    return showInactivePrompts
      ? innerRows
      : innerRows.filter((r) => r.isActive);
  }, [data, showInactivePrompts]);

  return (
    <div>
      <div className="mb-2 flex items-center gap-2">
        <Switch
          checked={showInactivePrompts}
          onCheckedChange={setShowInactivePrompts}
        />
        <Label>Show inactive prompts</Label>
      </div>
      <div className="rounded-md bg-white p-2">
        <DataTable
          columns={columns}
          data={rows}
          enableGlobalSearch
          initialSortingState={[{ id: 'createdAt', desc: true }]}
        />
      </div>
    </div>
  );
};
