import format from 'date-fns/format';
import { ExternalLinkIcon } from 'lucide-react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import { Badge, Box } from '@eluve/components';
import { externalChartsFragment } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

interface SignedBadgeProps {
  appointmentId: string;
}

const SignedAppointmentDetailsFragment = graphql(
  `
    fragment SignedAppointmentDetails on Appointments {
      __typename
      id
      doctor_interaction {
        noteSignedAt
        externalChartUrl
        user {
          __typename
          id
          firstName
          lastName
        }
      }
      ...externalCharts
    }
  `,
  [externalChartsFragment],
);

export const SignedBadge: React.FC<SignedBadgeProps> = ({ appointmentId }) => {
  const appointment = useCompleteFragment({
    fragment: SignedAppointmentDetailsFragment,
    key: {
      id: appointmentId,
    },
  });

  const interaction = appointment.doctor_interaction!;

  const signedAtDate = new Date(interaction.noteSignedAt!);

  const externalChartUrl = appointment?.external_charts?.[0]?.chartUrl;

  const signedTooltip = [
    'Signed by',
    interaction.user.firstName ?? '',
    interaction.user.lastName ?? '',
    format(signedAtDate, `'on' yyyy-MM-dd 'at' h:mm a`),
  ];

  return (
    <Box hStack className="flex-wrap px-2">
      <TooltipLabel label={signedTooltip.join(' ')}>
        <Badge
          variant="success"
          className="cursor-pointer bg-green text-sm text-greenContrast"
        >
          Signed
        </Badge>
      </TooltipLabel>
      {externalChartUrl && (
        <Box hStack asChild>
          <a
            href={externalChartUrl}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer text-sm text-brand-11 hover:underline"
          >
            View External Chart
            <ExternalLinkIcon size={18} />
          </a>
        </Box>
      )}
    </Box>
  );
};
