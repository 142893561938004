import { zodResolver } from '@hookform/resolvers/zod';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Button,
  Form,
  FormField,
  FormItem,
  FormMessage,
  Input,
  useToast,
} from '@eluve/components';
import { Logger } from '@eluve/logger';

export interface WaitlistProps {}

const formSchema = z.object({
  email: z.string().email(),
});

type Form = z.infer<typeof formSchema>;

export const WaitListPage: React.FC<WaitlistProps> = () => {
  const logger = useMemo(() => new Logger(WaitListPage.name), []);
  const [searchParams] = useSearchParams();

  const form = useForm<Form>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: searchParams.get('email') ?? '',
    },
  });

  const client = useApiClient();

  const { toast } = useToast();

  const onSubmit = async (data: Form) => {
    const response = await client.waitlist.addToWaitlist({
      body: {
        email: data.email,
      },
    });

    if (response.status === 201) {
      toast({
        title: 'You have been added to the waitlist!',
      });
      form.reset({
        email: '',
      });
    } else {
      logger.warn('Failed to add user to waitlist', { email: data.email });
      toast({
        title: 'Something went wrong. Try again later',
        variant: 'destructive',
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Waitlist | Eluve</title>
      </Helmet>
      <div className="grid h-full place-items-center bg-brand-2 py-16 sm:py-24">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative isolate overflow-hidden bg-brandGray900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Get notified when we’re launching.
            </h2>
            <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-contentSupporting">
              Speak, Document, Heal: Instant Clinical Notes
            </p>
            <Form {...form}>
              <form
                className="mx-auto mt-10 flex max-w-md items-start gap-x-4"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field, fieldState: { error } }) => (
                    <FormItem className="w-full flex-1">
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <Input
                        className="bg-white/5 text-white"
                        placeholder="Enter Your Email"
                        {...field}
                      />
                      {error && (
                        <FormMessage className="mt-4">
                          {error.message}
                        </FormMessage>
                      )}
                    </FormItem>
                  )}
                />
                <Button className="mt-2">Notify me</Button>
              </form>
            </Form>
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
              aria-hidden="true"
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient
                  id="759c1415-0410-454c-8f7c-9a820de03641"
                  cx={0}
                  cy={0}
                  r={1}
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(512 512) rotate(90) scale(512)"
                >
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};
