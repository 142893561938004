import { useMutation, useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { toast } from 'sonner';

import { eluveAdminHasuraContext } from '@eluve/apollo-client';
import { LanguageSelectorComboBox } from '@eluve/blocks';
import { H3, H5, P, VStack } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

export interface AdminTenantUserDefaultSettingsPageProps {}

const defaultSettingsFragment = graphql(`
  fragment DefaultSettings on DefaultTenantUserSettings @_unmask {
    __typename
    tenantId
    inputLanguage
    outputLanguage
  }
`);

const tenantDefaultSettings = graphql(
  `
    fragment TenantDefaultSettings on Tenants @_unmask {
      __typename
      id
      default_tenant_user_settings {
        ...DefaultSettings
      }
    }
  `,
  [defaultSettingsFragment],
);

const getDefaultTenantUserSettingsQuery = graphql(
  `
    query getDefaultTenantUserSettings($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        ...TenantDefaultSettings
      }
    }
  `,
  [tenantDefaultSettings],
);

const setDefaultTenantUserLanguageMutation = graphql(
  `
    mutation setDefaultTenantUserLanguage(
      $tenantId: uuid!
      $inputLanguage: String!
      $outputLanguage: String!
    ) {
      updateDefaultTenantUserSettingsByPk(
        pkColumns: { tenantId: $tenantId }
        _set: { inputLanguage: $inputLanguage, outputLanguage: $outputLanguage }
      ) {
        ...DefaultSettings
      }
    }
  `,
  [defaultSettingsFragment],
);

export const AdminTenantUserDefaultSettingsPage: React.FC<
  AdminTenantUserDefaultSettingsPageProps
> = () => {
  const tenantId = useTenantIdFromParams()!;

  const { data } = useSuspenseQuery(getDefaultTenantUserSettingsQuery, {
    variables: {
      tenantId,
    },
    context: eluveAdminHasuraContext,
  });

  const [setDefaultTenantUserLanguage] = useMutation(
    setDefaultTenantUserLanguageMutation,
    {
      onCompleted: () => toast.success('Updated default language'),
      onError: () => toast.error('Failed to update language'),
      optimisticResponse: (data) => ({
        updateDefaultTenantUserSettingsByPk: {
          __typename: 'DefaultTenantUserSettings' as const,
          tenantId,
          inputLanguage: data.inputLanguage,
          outputLanguage: data.outputLanguage,
        },
      }),
    },
  );

  const tenantDefaults = data.tenantsByPk?.default_tenant_user_settings;

  if (!tenantDefaults) {
    return (
      <span className="font-bold text-red-600">
        This tenant has no entry in default_tenant_user_settings. Something went
        wrong.
      </span>
    );
  }

  const inputLanguageCode = tenantDefaults.inputLanguage;
  const outputLanguagCode = tenantDefaults.outputLanguage;

  const handleLanguageUpdate = async (input: string, output: string) => {
    await setDefaultTenantUserLanguage({
      variables: {
        tenantId,
        inputLanguage: input,
        outputLanguage: output,
      },
      context: eluveAdminHasuraContext,
    });
  };

  return (
    <VStack gap={5}>
      <div>
        <H3>Default Tenant User Settings</H3>
        <P>
          When new users are added to this tenant, these will be the default
          settings granted to them.
        </P>
      </div>

      <VStack>
        <H5>Spoken Language</H5>
        <LanguageSelectorComboBox
          selectButtonStyles="w-full"
          selectedLanguageCode={inputLanguageCode}
          onSelectedLanguageCode={async (newCode) => {
            await handleLanguageUpdate(newCode, outputLanguagCode);
          }}
        />
      </VStack>
      <VStack>
        <H5>Notes Language</H5>
        <LanguageSelectorComboBox
          selectButtonStyles="w-full"
          selectedLanguageCode={outputLanguagCode}
          onSelectedLanguageCode={async (newCode) => {
            await handleLanguageUpdate(inputLanguageCode, newCode);
          }}
        />
      </VStack>
    </VStack>
  );
};
