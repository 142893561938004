import {
  ChevronDown,
  ChevronUp,
  CircleIcon,
  ExternalLinkIcon,
} from 'lucide-react';
import React, { useState } from 'react';

import { Button, Collapsible, CollapsibleContent, P } from '@eluve/components';
import { formatToLocale, timestampToHumanReadable } from '@eluve/date-utils';
import { VendorTypesEnum } from '@eluve/graphql-types';
import { getHumanFriendlyError, getVendorProvider } from '@eluve/vendor';

type EhrOverviewProps = {
  vendor: VendorTypesEnum;
  domain: string;
  logo: (c: boolean) => JSX.Element;
  status?: 'CONNECTED' | 'DISCONNECTED';
  error?: string | null;
  syncedAt?: number;
  showStatusDetails?: boolean;
};

type EhrStatusDetailsProps = {
  vendor: VendorTypesEnum;
  domain: string;
  status?: 'CONNECTED' | 'DISCONNECTED';
  error?: string | null;
  syncedAt?: number;
};

export const EhrStatusDetails: React.FC<EhrStatusDetailsProps> = ({
  vendor,
  domain,
  status,
  error,
  syncedAt,
}) => {
  const vendorProvider = getVendorProvider(vendor);

  const getEhrStatusEntry = (key: string, value: string | undefined) => {
    if (!value) {
      return null;
    }
    return (
      <div className="mb-3 mt-3 flex flex-row items-center justify-between">
        <P className="text-brandGray400 md:text-xs">{key}</P>
        <P className="text-brandGray600 md:text-xs">{value}</P>
      </div>
    );
  };
  const errorDisplay = (errorMessage?: string) => (
    <div className="flex flex-row self-center bg-amber-50 p-1">
      <P className="font-light md:text-xs">
        {getHumanFriendlyError(errorMessage ?? '')?.message}
      </P>
    </div>
  );

  return (
    <div
      className="mb-3 mt-3 w-full"
      key={`current-account-overview-${vendor}`}
    >
      {status === 'DISCONNECTED' && errorDisplay(error ?? '')}
      {getEhrStatusEntry('EHR', vendor)}
      {getEhrStatusEntry('Status', status)}
      {getEhrStatusEntry(
        'Last Synced',
        syncedAt ? formatToLocale(new Date(syncedAt).toString()) : '',
      )}
      <div className="flex flex-col justify-between">
        <Button
          variant="ghost"
          className="mt-2 justify-start border fill-transparent px-0 hover:bg-transparent"
          onClick={() => {
            window.open(
              vendorProvider.getHomeUrl(domain),
              '_blank',
              'noopener,noreferrer',
            );
          }}
        >
          <div className="flex w-full items-center">
            <ExternalLinkIcon className="m-2" size={20} />
            <span className="text-xs">{domain}</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export const EhrOverview: React.FC<EhrOverviewProps> = ({
  vendor,
  domain,
  logo,
  status,
  error,
  syncedAt,
  showStatusDetails = false,
}) => {
  let subTitle = '';
  if (status === 'DISCONNECTED') {
    subTitle = 'Sync Failed';
  } else {
    if (syncedAt) {
      subTitle = `Synced ${timestampToHumanReadable(syncedAt)}`;
    }
  }

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div key={`account-status-${vendor}`} className="flex flex-row">
      <div className="flex w-full flex-col">
        <div className="flex flex-row justify-between">
          <div className="flex h-10 w-full flex-row self-center">
            {logo(status === 'CONNECTED')}
            <div className="ml-3">
              <P>{vendor}</P>
              <div className="flex flex-row">
                <div className="self-center">
                  {status === 'CONNECTED' && (
                    <CircleIcon
                      fill={'#46A758'}
                      size={8}
                      className="mr-2 text-transparent"
                    />
                  )}
                  {status === 'DISCONNECTED' && (
                    <CircleIcon
                      fill={'#F09234'}
                      size={8}
                      className="mr-2 text-transparent"
                    />
                  )}
                </div>
                <P className="text-brandGray400 md:text-xs">{subTitle}</P>
              </div>
            </div>
          </div>
          {showStatusDetails && (
            <div className="self-center">
              {isOpen ? (
                <div
                  className="cursor-pointer p-1"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <ChevronUp size={15} />
                </div>
              ) : (
                <div
                  className="cursor-pointer p-1"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <ChevronDown size={15} />
                </div>
              )}
            </div>
          )}
        </div>
        <Collapsible open={isOpen}>
          <CollapsibleContent>
            <EhrStatusDetails
              vendor={vendor}
              domain={domain}
              error={error}
              status={status}
              syncedAt={syncedAt}
            />
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  );
};
