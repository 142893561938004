const baseUrl = 'https://us5.datadoghq.com/logs';

const defaultColumns = [
  'host',
  'service',
  '@appVersion',
  '@userId',
  '@tabId',
  '@appointmentId',
];

const defaultParams = {
  agg_m_source: 'base',
  agg_m: 'count',
  agg_t: 'count',
  fromUser: 'true',
  live: 'true',
  messageDisplay: 'inline',
  refresh_mode: 'sliding',
  storage: 'hot',
  stream_sort: 'time,desc',
  viz: 'stream',
};

const FIFTEEN_DAYS_IN_MS = 15 * 24 * 60 * 60 * 1000;

const getDatadogUrl = (query: string, columns: string[]): string => {
  const now = Date.now();
  const params: { [index: string]: string } = {
    ...defaultParams,
    cols: columns.join(','),
    from_ts: (now - FIFTEEN_DAYS_IN_MS).toString(),
    query,
    to_ts: now.toString(),
  };
  const url = new URL(baseUrl);

  Object.keys(params).forEach((key) => {
    if (params[key] != null) {
      url.searchParams.append(key, params[key]);
    }
  });

  return url.toString();
};

export const getDatadogUrlForUser = (
  userId: string,
  columns?: string[],
): string => {
  const query = `@userId:${userId}`;
  return getDatadogUrl(query, columns ?? defaultColumns);
};
