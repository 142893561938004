import { MicOffIcon, PlayCircleIcon } from 'lucide-react';
import { useState } from 'react';
import { useMount } from 'react-use';

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  P,
} from '@eluve/components';
import { useSummary } from '@eluve/frontend-appointment-hooks';
import { useNamedLogger } from '@eluve/logger';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import {
  AppointmentUserMicSettings,
  useSelectedMicrophoneDevice,
} from '@eluve/smart-blocks';

import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../TranscriptionMachineProvider';

export const ErrorDialog = () => {
  const logger = useNamedLogger('MicrophoneFailedDialog');
  const machineState = useTranscriptionMachineSelector((s) => s);
  const { microphoneStatus, sessionAppointmentId: appointmentId } =
    machineState.context;
  const userId = useUserIdFromSession();
  const tenantId = useTenantIdFromParams();
  const [open, setOpen] = useState(true);
  const { isSummaryAvailable } = useSummary();

  const [selectedMicId, setSelectedMicId] = useSelectedMicrophoneDevice();

  const { send } = useTranscriptionMachineActor();

  useMount(() => {
    logger.warn('MicrophoneFailedDialog shown', {
      microphoneStatus,
      appointmentId,
      userId,
      tenantId,
    });
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent overlay={false}>
        <DialogHeader>
          <DialogTitle className="text-destructive">
            <MicOffIcon className="mr-4 inline-flex size-6" />
            {`Your microphone is ${microphoneStatus}.`}
          </DialogTitle>
          <DialogDescription>
            <AppointmentUserMicSettings
              selectedMicId={selectedMicId}
              setSelectedMicId={setSelectedMicId}
            />
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="secondary"
            onClick={() =>
              send({ type: 'STOP', hasExistingSummary: isSummaryAvailable })
            }
          >
            Generate Note
          </Button>
          <Button onClick={() => send({ type: 'RESUME' })}>
            <div className="flex flex-row">
              <PlayCircleIcon className="mr-2 h-4 w-4" />
              <P>Resume</P>
            </div>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const MicrophoneFailedDialog = () => {
  const machineState = useTranscriptionMachineSelector((s) => s);
  const hasFailed =
    machineState.can({ type: 'RESUME' }) &&
    machineState.context.microphoneStatus === 'disconnected';

  if (!hasFailed) {
    return null;
  }

  return <ErrorDialog />;
};
