import minBy from 'lodash/minBy';

import { AppointmentTask, UploadFileEvent } from '../types';

export const selectNextActiveTask = (tasks: AppointmentTask[]) => {
  const markAppointmentCompleteTask = tasks.find(
    (t) => t.type === 'TASK.GENERATE_NOTE',
  );

  if (markAppointmentCompleteTask) {
    return markAppointmentCompleteTask;
  }

  const foregroundUploadTask = tasks.find(
    (t) => t.type === 'TASK.UPLOAD_FILE' && !t.isBackgroundUpload,
  );

  if (foregroundUploadTask) {
    return foregroundUploadTask;
  }

  const backgroundUploadTasks = tasks.filter(
    (t) => t.type === 'TASK.UPLOAD_FILE' && t.isBackgroundUpload,
  );

  if (backgroundUploadTasks.length) {
    const task = minBy(
      backgroundUploadTasks,
      (t) => (t as UploadFileEvent).attemptNumber,
    );
    if (task) {
      return task;
    }
  }

  const [task] = tasks;
  return task;
};
