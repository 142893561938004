import { useMutation } from '@apollo/client';
import { PackageMinus, PackagePlus } from 'lucide-react';
import React from 'react';
import { toast } from 'sonner';
import { match } from 'ts-pattern';

import { useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  P,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export interface TogglePromptTemplateActiveActionProps {
  outputTemplateId: string;
}

const toggleOutputTemplateActiveMutation = graphql(`
  mutation toggleOutputTemplateActive($id: uuid!, $isActive: Boolean!) {
    updateLlmOutputTemplatesByPk(
      pkColumns: { id: $id }
      _set: { isActive: $isActive }
    ) {
      __typename
      id
      isActive
    }
  }
`);

export const outputTemplateActiveFragment = graphql(`
  fragment OutputTemplateActive on LlmOutputTemplates @_unmask {
    __typename
    id
    isActive
  }
`);

export const ToggleOutputTemplateActiveAction: React.FC<
  TogglePromptTemplateActiveActionProps
> = ({ outputTemplateId }) => {
  const [updatePromptTemplateIsActive] = useMutation(
    toggleOutputTemplateActiveMutation,
    {
      optimisticResponse: (data) => ({
        updateLlmOutputTemplatesByPk: {
          __typename: 'LlmOutputTemplates' as const,
          id: data.id,
          isActive: data.isActive,
        },
      }),
      onCompleted: () => {
        toast.success('Output Template updated.');
      },
      onError: () => {
        toast.error('Failed to update Output Template');
      },
    },
  );

  const outputTemplateSettings = useCompleteFragment({
    fragment: outputTemplateActiveFragment,
    key: {
      id: outputTemplateId,
    },
  });

  const toggleActive = async () => {
    await updatePromptTemplateIsActive({
      variables: {
        id: outputTemplateId,
        isActive: !outputTemplateSettings.isActive,
      },
    });
  };

  const { label, description, action, Component } = match(
    outputTemplateSettings.isActive,
  )
    .with(true, () => {
      const description = (
        <div>
          <P>
            Marking this Output Template as inactive will mean users can no
            longer use it for generating LLM outputs
          </P>
        </div>
      );

      return {
        label: 'Mark this Output Template as inactive',
        description,
        action: 'Deactivate',
        Component: <PackageMinus className="text-orange" />,
      };
    })
    .otherwise(() => ({
      label: 'Mark this Output Template as active',
      description: (
        <P>
          Marking this Output Template as active will make it available for
          users
        </P>
      ),
      action: 'Activate',
      Component: <PackagePlus className="text-green" />,
    }));

  return (
    <AlertDialog>
      <TooltipLabel label={label}>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline">
            {Component}
          </Button>
        </AlertDialogTrigger>
      </TooltipLabel>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-11">
            Are you sure?
          </AlertDialogTitle>
          <AlertDialogDescription asChild>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel autoFocus>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={toggleActive}
          >
            {action}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
