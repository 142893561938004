import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { dynamicArtifactTemplateSchema } from '@eluve/llm-outputs';

const c = initContract();

export const adminLlmContract = c.router(
  {
    summarizeTranscriptSync: {
      method: 'POST',
      path: '/summarize-transcript',
      body: z.object({
        transcript: z.string(),
        promptTemplateId: z.string(),
        dynamicArtifactTemplate: dynamicArtifactTemplateSchema,
      }),
      responses: {
        201: z.object({
          content: z.unknown(),
        }),
      },
    },
  },
  { pathPrefix: '/llm' },
);
