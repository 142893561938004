import { PlusIcon } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { Button, HStack, PageTitle } from '@eluve/components';
import { PromptTemplatesList } from '@eluve/frontend-feature-prompt-templates';

export interface PromptTemplatesListPageProps {}

export const PromptTemplatesListPage: React.FC<
  PromptTemplatesListPageProps
> = () => {
  return (
    <>
      <HStack justify="between">
        <div className="flex-1">
          <PageTitle>Prompt Templates</PageTitle>
        </div>
        <Link to="create">
          <Button>
            <PlusIcon className="mr-2 h-5" />
            Create a Prompt Template
          </Button>
        </Link>
      </HStack>
      <PromptTemplatesList />
    </>
  );
};
