import { useSubscription } from '@apollo/client';
import { useMemo } from 'react';

import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { useAppointmentId } from './appointment.context';

const listenCompletedBatchSegmentsStreamSubscription = graphql(`
  subscription listenBatchTranscriptionSegmentSTream(
    $tenantId: uuid!
    $appointmentId: uuid!
    $initialValue: timestamptz!
  ) {
    appointmentSegmentsStream(
      where: {
        tenantId: { _eq: $tenantId }
        appointmentId: { _eq: $appointmentId }
        raw_transcript: {
          transcriptType: { _eq: BATCH }
          transcriptionStatus: { _eq: COMPLETED }
        }
      }
      batchSize: 1
      cursor: { initialValue: { updatedAt: $initialValue }, ordering: ASC }
    ) {
      __typename
      id
      transcript
      updatedAt
      recordingDuration
      recordingStartedAt
    }
  }
`);

/**
 * Syncs completed batch transcription segments for the appointment.
 */
export const useAppointmentTranscriptionSync = (
  startingTimestamp?: string | null,
) => {
  const tenantId = useTenantIdFromParams();
  const appointmentId = useAppointmentId();

  const initialValue = useMemo(
    () => startingTimestamp ?? new Date(0).toISOString(),
    [startingTimestamp],
  );

  useSubscription(listenCompletedBatchSegmentsStreamSubscription, {
    variables: {
      tenantId: tenantId!,
      appointmentId,
      initialValue,
    },
  });
};
