import { usePostHog } from 'posthog-js/react';
import { useMount } from 'react-use';

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  NewButton,
  textStyles,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';

import {
  useAppointmentTasksActor,
  useAppointmentTasksSelector,
} from './AppointmentTasksMachineProvider';

const ExistingSummaryDialog: React.FC = () => {
  const postHog = usePostHog();
  const { send } = useAppointmentTasksActor();

  const logger = useNamedLogger('OverridingExistingSummaryDialog');

  useMount(() => {
    postHog.capture('overriding_existing_summary_dialog_shown');
    logger.info('OverridingExistingSummaryDialog shown');
  });

  return (
    <Dialog
      defaultOpen={true}
      onOpenChange={(open) => {
        if (!open) {
          send({ type: 'CANCEL_GENERATION' });
        }
      }}
    >
      <DialogContent
        onInteractOutside={(e) => e.preventDefault()}
        className="max-w-96"
      >
        <DialogHeader>
          <DialogTitle className="text-destructive">
            <p
              className={textStyles.title({
                size: 'm',
                weight: 'semibold',
              })}
            >
              Overwrite existing note?
            </p>
          </DialogTitle>
          <DialogDescription>
            <p
              className={textStyles.body({
                size: 'm',
                weight: 'medium',
              })}
            >
              If you generate note your existing note will be overwritten. Is
              that ok?
            </p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <NewButton
              type="outlineSubtle"
              size="s"
              text="No"
              onClick={() => {
                logger.info(
                  'User clicked cancel in OverridingExistingSummaryDialog',
                );
                send({ type: 'CANCEL_GENERATION' });
              }}
            />
          </DialogClose>
          <NewButton
            size="s"
            text="Yes, Proceed to New Note"
            onClick={() => {
              logger.info(
                'User clicked YES in OverridingExistingSummaryDialog',
              );
              send({ type: 'PROCEED_WITH_GENERATION' });
            }}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const OverridingExistingSummaryDialog = () => {
  const shouldShowDialog = useAppointmentTasksSelector((s) =>
    s.matches({
      InProgress: {
        GenerateNote: 'ShowOverridingExistingSummaryDialog',
      },
    }),
  );

  if (!shouldShowDialog) {
    return null;
  }

  return <ExistingSummaryDialog />;
};
