import { FlaskConical, LogOutIcon } from 'lucide-react';
import React, { Suspense } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';

import { Button } from '@eluve/components';

const adminRoutes = [
  { name: 'Source Artifact Review', path: 'source-artifact-review' },
];
export const EvalLayout: React.FC = () => {
  return (
    <div className="min-h-full">
      <div className="bg-brandGray800 pb-32">
        <header className="py-10">
          <div className="mx-auto w-full px-2  lg:px-8">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <FlaskConical className="h-10 w-10 text-yellow" />
                <h1 className="text-3xl font-bold tracking-tight text-white">
                  Eluve Eval
                </h1>
              </div>
              <div className="flex gap-2">
                <Link to="/">
                  <Button>
                    Return to App <LogOutIcon className="ml-2" />
                  </Button>
                </Link>
              </div>
            </div>
            <div className="flex items-center gap-8 py-4">
              {adminRoutes.map((route) => (
                <NavLink
                  key={route.name}
                  to={route.path}
                  className={({ isActive }) =>
                    [
                      'p-1 text-white underline',
                      isActive ? 'bg-gray-700 px-2 no-underline' : '',
                    ].join(' ')
                  }
                >
                  {route.name}
                </NavLink>
              ))}
            </div>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        <div className="mx-auto w-full px-2 pb-12 lg:px-8">
          <div className="min-h-96 rounded-lg bg-white px-5 py-6 shadow sm:px-6">
            <div className="w-full">
              <Suspense fallback={'Loading...'}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
