import { useMutation } from '@apollo/client';
import React from 'react';
import { toast } from 'sonner';

import {
  eluveAdminHasuraContext,
  useCompleteFragment,
} from '@eluve/apollo-client';
import { Switch } from '@eluve/components';
import { GlobalFeatureFlagsEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

export const globalFeatureFlagStatusFragment = graphql(`
  fragment GlobalFeatureFlagStatus on GlobalFeatureFlagsStatus @_unmask {
    __typename
    flag
    isEnabled
    updatedAt
  }
`);

const setGlobalFeatureFlagMutation = graphql(
  `
    mutation setGlobalFeatureFlag(
      $flag: GlobalFeatureFlagsEnum!
      $isEnabled: Boolean!
    ) {
      insertGlobalFeatureFlagsStatusOne(
        object: { flag: $flag, isEnabled: $isEnabled }
        onConflict: {
          constraint: global_feature_flags_status_pkey
          updateColumns: [isEnabled]
        }
      ) {
        __typename
        ...GlobalFeatureFlagStatus
      }
    }
  `,
  [globalFeatureFlagStatusFragment],
);

export interface GlobalFeatureFlagsToggleProps {
  flag: GlobalFeatureFlagsEnum;
}

export const GlobalFeatureFlagsToggle: React.FC<
  GlobalFeatureFlagsToggleProps
> = ({ flag }) => {
  const [updateFeatureFlag] = useMutation(setGlobalFeatureFlagMutation, {
    onCompleted: () => toast.success(`Global Feature ${flag} updated!`),
    onError: () => toast.error(`Failed to feature ${flag}`),
    optimisticResponse: (data) => ({
      insertGlobalFeatureFlagsStatusOne: {
        __typename: 'GlobalFeatureFlagsStatus' as const,
        ...data,
        updatedAt: new Date().toISOString(),
      },
    }),
  });

  const data = useCompleteFragment({
    fragment: globalFeatureFlagStatusFragment,
    key: { flag },
    strict: false,
  });

  const submit = async (isEnabled: boolean) => {
    try {
      await updateFeatureFlag({
        context: eluveAdminHasuraContext,
        variables: {
          flag,
          isEnabled,
        },
      });
    } catch (e) {
      toast.error(`Failed to update access to feature ${flag}`);
    }
  };

  return (
    <Switch
      onCheckedChange={(isChecked) => submit(isChecked)}
      checked={data?.isEnabled ?? false}
    />
  );
};
