import {
  Calendar,
  CircleHelp,
  Clock,
  Files,
  Info,
  Loader2Icon,
  MapPin,
  PauseCircle,
  Pencil,
  PlayCircle,
  Plus,
  RotateCw,
  Search,
  ShieldCheck,
  Star,
  Tag,
  Trash,
  User,
} from 'lucide-react';
import React from 'react';
import { VariantProps } from 'tailwind-variants';

import { tv } from './cn';

const styles = tv({
  base: '',
  variants: {
    size: {
      xxs: 'size-3.5 stroke-1',
      xs: 'size-4 stroke-[1.5px]',
      sm: 'size-5 stroke-[1.5px]',
      md: 'size-6 stroke-[1.8px]',
      lg: 'size-7 stroke-2',
    },
  },
});

export const Icons = {
  Calendar,
  CircleHelp,
  Clock,
  Files,
  Info,
  MapPin,
  PauseCircle,
  Pencil,
  PlayCircle,
  Plus,
  RotateCw,
  Search,
  ShieldCheck,
  Star,
  Tag,
  Trash,
  User,
  Loader2Icon,
};

type IconNames = keyof typeof Icons;

export type IconProps = VariantProps<typeof styles> & {
  className?: string;
  name: IconNames;
};

export const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ name, size, className }, ref) => {
    const Component = Icons[name];
    const classes = styles({ size, className });

    return <Component ref={ref} className={classes} />;
  },
);
