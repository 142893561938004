import { Download, Headphones } from 'lucide-react';
import React, { useState } from 'react';
import { toast } from 'sonner';

import { useApiClient } from '@eluve/api-client-provider';
import { AudioPlayer } from '@eluve/audio-player';
import { TooltipLabel } from '@eluve/blocks';
import { Button, HStack, VStack } from '@eluve/components';
import { AppointmentFileTypesEnum } from '@eluve/graphql-types';

export interface AppointmentFileActionsColumnProps {
  tenantId: string;
  appointmentId: string;
  fileId: string;
  filePath: string;
  fileType: AppointmentFileTypesEnum;
}

const NonAudioFileTypes: AppointmentFileTypesEnum[] = [
  'PDF_SUMMARY',
  'RAW_TRANSCRIPT_METADATA',
];

export const AppointmentFileActionsColumn: React.FC<
  AppointmentFileActionsColumnProps
> = ({ appointmentId, fileId, filePath, tenantId, fileType }) => {
  const api = useApiClient();
  const [audioUrl, setAudioUrl] = useState<string>('');

  const getFile = async (type: 'download' | 'listen') => {
    if (!filePath) return;
    const result = await api.adminAppointment.getAppointmentFileSignedUrl({
      params: { tenantId, appointmentId, fileId },
    });

    if (result.status === 200 && result.body.presignedUrl) {
      if (type === 'listen') {
        setAudioUrl(result.body.presignedUrl);
      } else {
        const fetched = await fetch(result.body.presignedUrl);
        const blob = await fetched.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = filePath.split('/').pop() ?? '';
        link.click();

        window.URL.revokeObjectURL(url);
      }
    } else {
      toast.error('Failed to generate file URL');
    }
  };

  if (!filePath) return null;

  return (
    <VStack>
      <HStack>
        {!NonAudioFileTypes.includes(fileType) && (
          <TooltipLabel label="Listen to audio">
            <Button
              variant="outline"
              size="icon"
              onClick={() => getFile('listen')}
              disabled={!filePath || Boolean(audioUrl)}
            >
              <Headphones />
            </Button>
          </TooltipLabel>
        )}

        <TooltipLabel label="Download">
          <Button
            variant="outline"
            size="icon"
            onClick={() => getFile('download')}
            disabled={!filePath}
          >
            <Download />
          </Button>
        </TooltipLabel>
      </HStack>
      <HStack>
        {!NonAudioFileTypes.includes(fileType) && (
          <AudioPlayer audioUrl={audioUrl} />
        )}
      </HStack>
    </VStack>
  );
};
