import { HStack, ReskinFooter, VStack } from '@eluve/components';

import { AppointmentLastSaved } from './AppointmentLastSaved';
import { AppointmentPDFOutputControls } from './AppointmentPDFOutputControls';
import { SignAndSyncAppointmentButton } from './SignAndSyncAppointmentButton';

export const AppointmentSummaryFooter: React.FC = () => {
  return (
    <ReskinFooter className="py-10 sm:py-0">
      <VStack
        justify="between"
        gap={1}
        className="mx-auto max-w-[1024px] px-2 sm:flex-row sm:items-center sm:gap-2"
      >
        <AppointmentLastSaved />

        <HStack wFull={true} className="sm:w-fit">
          <AppointmentPDFOutputControls />
          <SignAndSyncAppointmentButton />
        </HStack>
      </VStack>
    </ReskinFooter>
  );
};
