import { useSuspenseQuery } from '@apollo/client';
import React, { Suspense } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { eluveAdminHasuraContext } from '@eluve/apollo-client';
import { Button, H3, HStack, PageTitle, Stat } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { TabNav, useTenantName } from '@eluve/smart-blocks';

import { adminTenantFeatureFlagsFragment } from '../../components/TenantFeatureFlags';
import {
  llmOutputTypesFragment,
  tenantLlmOutputsFragment,
} from '../../components/TenantLlmOutputs';

const adminTenantQuery = graphql(
  `
    query getAdminTenantByIdSummary($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        name
        appointmentsAggregate {
          aggregate {
            count
          }
        }
        tenantUsersAggregate {
          aggregate {
            count
          }
        }
        ...AdminTenantFeatureFlags
        ...TenantLlmOutputs
      }
    }
  `,
  [adminTenantFeatureFlagsFragment, tenantLlmOutputsFragment],
);

const getLlmOutputTypes = graphql(
  `
    query getLlmOutputTypes {
      ...LlmOutputTypes
    }
  `,
  [llmOutputTypesFragment],
);

export const AdminTenant: React.FC = () => {
  const { tenantId } = useParams() as { tenantId: string };

  const {
    data: { tenantsByPk: tenant },
  } = useSuspenseQuery(adminTenantQuery, {
    variables: {
      tenantId: tenantId,
    },
  });

  useSuspenseQuery(getLlmOutputTypes, {
    context: eluveAdminHasuraContext,
  });

  const name = useTenantName(tenantId);
  return (
    <div>
      <PageTitle
        isFullWidth
        subtitle={
          <HStack justify="between" wrap>
            <H3>{`ID: ${tenantId}`}</H3>
            <div>
              <Button asChild variant="outline">
                <Link to="../dash">View Tenant Dash</Link>
              </Button>
              <Button asChild className="ml-2" variant="outline">
                <Link to="../users">Manage Users</Link>
              </Button>
            </div>
          </HStack>
        }
      >
        {name}
      </PageTitle>

      <div className="mt-2 grid gap-4">
        <div className="flex w-full gap-2">
          <Stat
            name="Appointments"
            stat={tenant?.appointmentsAggregate?.aggregate?.count ?? 0}
          />
          <Stat
            name="Users"
            stat={tenant?.tenantUsersAggregate?.aggregate?.count ?? 0}
          />
        </div>
        <TabNav
          tabs={[
            {
              name: 'Feature Flags',
              link: 'feature-flags',
            },
            {
              name: 'LLM Outputs',
              link: 'llm-outputs',
            },
            {
              name: 'Prompt Templates',
              link: 'prompt-templates',
            },
            {
              name: 'LLM Output Templates',
              link: 'llm-output-templates',
            },
            {
              name: 'Imported LLM Output Templates',
              link: 'imported-llm-output-templates',
            },
            {
              name: 'Default User Settings',
              link: 'default-user-settings',
            },
            {
              name: 'Code Types',
              link: 'code-types',
            },
          ]}
        />
        <div className="mt-2">
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
};
