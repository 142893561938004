import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';
import { LlmOutputTypesLookup } from '@eluve/graphql-types';

import {
  enabledTenantLlmOutputTemplateFragment,
  getTenantUserSettingsQuery,
  medicalNotesTemplatesFragment,
  tenantUserSettingsFragment,
} from './operations';

interface UsePromptTemplatesForTenantParams {
  tenantId: string;
  userId: string;
  dynamicOutputTemplatesEnabled?: boolean;
}

export const usePromptTemplatesForTenant = ({
  tenantId,
  userId,
  dynamicOutputTemplatesEnabled,
}: UsePromptTemplatesForTenantParams) => {
  const { data, loading: loadingTemplates } = useQuery(
    getTenantUserSettingsQuery,
    {
      variables: {
        tenantId,
        userId,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const unfilteredPromptTemplates =
    useCompleteFragment({
      fragment: medicalNotesTemplatesFragment,
      key: QUERY_ROOT_ID,
      strict: false,
    })?.activePromptTemplates ?? [];

  const tenantUserSettings = useCompleteFragment({
    fragment: tenantUserSettingsFragment,
    key: { tenantId, userId },
    strict: false,
  });

  const usersOutputTemplates = useMemo(() => {
    return (tenantUserSettings?.users_llm_output_templates ?? []).map(
      (t) => t.llm_output_template,
    );
  }, [tenantUserSettings]);

  const enabledTenantLlmOutputTemplates = useCompleteFragment({
    fragment: enabledTenantLlmOutputTemplateFragment,
    key: { id: tenantId },
    strict: false,
  });

  const enabledTenantOutputTemplates = useMemo(() => {
    return (
      enabledTenantLlmOutputTemplates?.tenant_llm_output_templates ?? []
    ).map((t) => t.llm_output_template);
  }, [enabledTenantLlmOutputTemplates]);

  const activeOutputTemplates = useMemo(
    () => [...usersOutputTemplates, ...enabledTenantOutputTemplates],
    [usersOutputTemplates, enabledTenantOutputTemplates],
  );

  const activeSOAPPromptTemplates = (unfilteredPromptTemplates ?? []).filter(
    (template) => {
      if (template.outputType === LlmOutputTypesLookup.DYNAMIC_OUTPUT) {
        return (
          dynamicOutputTemplatesEnabled && Boolean(activeOutputTemplates.length)
        );
      }
      return true;
    },
  );

  const userSettingsPromptTemplateId =
    data?.tenantUsersByPk?.settings?.promptTemplateId;
  const userSettingsOutputTemplateId =
    data?.tenantUsersByPk?.settings?.llmOutputTemplateId;

  const defaultOutputTemplateId = useMemo(() => {
    if (!activeOutputTemplates.length) {
      return null;
    }

    const userDefaultTemplate = activeOutputTemplates.find(
      (t) => t.id === userSettingsOutputTemplateId,
    );

    return userDefaultTemplate
      ? userDefaultTemplate.id
      : activeOutputTemplates[0]?.id;
  }, [activeOutputTemplates, userSettingsOutputTemplateId]);

  const defaultPromptTemplateId = useMemo(() => {
    if (!activeSOAPPromptTemplates.length) {
      return null;
    }

    const userDefaultTemplate = activeSOAPPromptTemplates.find(
      (t) => t.id === userSettingsPromptTemplateId,
    );

    return (
      userDefaultTemplate?.id ??
      activeSOAPPromptTemplates.find((t) => t.isCurrentDefault)?.id ??
      activeSOAPPromptTemplates[0]?.id ??
      null
    );
  }, [activeSOAPPromptTemplates, userSettingsPromptTemplateId]);

  return {
    activeSOAPPromptTemplates,
    defaultPromptTemplateId,
    defaultOutputTemplateId,
    userSettingsPromptTemplateId,
    activeOutputTemplates,
    userSettingsOutputTemplateId,
    loadingTemplates,
  };
};
