import { create } from 'zustand';

import { Box, Label, Switch } from '@eluve/components';

export const useIsSimulatedDegradedTranscription = create(() => false);

export const SimulateDegradedTranscription = () => {
  const isSimulatedDegradedTranscription =
    useIsSimulatedDegradedTranscription();

  return (
    <Box vStack>
      <Label>Simulate Degraded Transcription</Label>
      <Switch
        checked={isSimulatedDegradedTranscription}
        onCheckedChange={(checked) =>
          useIsSimulatedDegradedTranscription.setState(checked)
        }
      />
    </Box>
  );
};
