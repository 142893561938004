import { graphql } from '@eluve/graphql.tada';

export const llmOutputTemplateFragment = graphql(`
  fragment LlmOutputTemplate on LlmOutputTemplates @_unmask {
    __typename
    id
    name
    description
    dynamicArtifactTemplate
    isActive
    llmOutputType
  }
`);
export const externalChartTemplateFragment = graphql(`
  fragment ExternalChartTemplate on ExternalChartTemplates @_unmask {
    __typename
    id
    externalEhrId
    externalChartTemplateId
    tenantId
  }
`);

export const llmOutputTemplateWithExternalChartTemplatesFragment = graphql(
  `
    fragment LlmOutputTemplateWithExternalChartTemplatesFragment on LlmOutputTemplates
    @_unmask {
      ...LlmOutputTemplate
      external_chart_templates {
        ...ExternalChartTemplate
      }
    }
  `,
  [llmOutputTemplateFragment, externalChartTemplateFragment],
);

export const activeDynamicOutputPromptTemplatesFragment = graphql(`
  fragment ActiveDynamicOutputPromptTemplates on query_root @_unmask {
    activePromptTemplates(
      where: { outputType: { _in: ["DYNAMIC_OUTPUT"] } }
      orderBy: { name: ASC }
    ) {
      __typename
      id
      isCurrentDefault
      name
      description
      outputType
    }
  }
`);

export const getOutputTemplateQuery = graphql(
  `
    query getOutputTemplate($id: uuid!) {
      llmOutputTemplatesByPk(id: $id) {
        ...LlmOutputTemplateWithExternalChartTemplatesFragment
      }
      ...ActiveDynamicOutputPromptTemplates
    }
  `,
  [
    llmOutputTemplateWithExternalChartTemplatesFragment,
    activeDynamicOutputPromptTemplatesFragment,
  ],
);

export const createOutputTemplateMutation = graphql(`
  mutation createOutputTemplate($input: LlmOutputTemplatesInsertInput!) {
    insertLlmOutputTemplatesOne(object: $input) {
      __typename
      id
    }
  }
`);

export const updateOutputTemplateMutation = graphql(
  `
    mutation updateOutputTemplate(
      $id: uuid!
      $name: String!
      $description: String!
      $dynamicArtifactTemplate: jsonb!
    ) {
      updateLlmOutputTemplatesByPk(
        pkColumns: { id: $id }
        _set: {
          name: $name
          description: $description
          dynamicArtifactTemplate: $dynamicArtifactTemplate
        }
      ) {
        ...LlmOutputTemplate
      }
    }
  `,
  [llmOutputTemplateFragment],
);
