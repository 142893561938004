import { match } from 'ts-pattern';

import { AppointmentTask, Context } from '../types';

export type TaskContext = Pick<Context, 'activeTask' | 'tasks'>;

export const calculateRemainingTasks = (
  context: TaskContext,
): AppointmentTask[] => {
  const { tasks, activeTask } = context;
  if (activeTask === undefined) {
    return context.tasks;
  }

  const remainingTasks = match(activeTask)
    .with({ type: 'TASK.GENERATE_NOTE' }, (markCompleteTask) =>
      tasks.filter((t) => {
        if (t.type === 'TASK.GENERATE_NOTE') {
          return t.appointmentId !== markCompleteTask.appointmentId;
        }

        return true;
      }),
    )
    .with({ type: 'TASK.UPLOAD_FILE' }, (activeUploadTask) =>
      tasks.filter((t) => {
        if (t.type === 'TASK.UPLOAD_FILE') {
          return (
            t.appointmentId !== activeUploadTask.appointmentId ||
            t.fileName !== activeUploadTask.fileName
          );
        }

        return true;
      }),
    )
    .exhaustive();

  return remainingTasks;
};
