import { VariantProps } from 'tailwind-variants';

import { tv } from './cn';

const textContentColors = tv({
  variants: {
    color: {
      primary: 'text-contentPrimary',
      secondary: 'text-contentSecondary',
      tertiary: 'text-contentTertiary',
      supporting: 'text-contentSupporting',
    },
  },
  defaultVariants: {
    color: 'primary',
  },
});

const headingTextStyles = tv({
  extend: textContentColors,
  base: 'leading-[132%]',
  variants: {
    weight: {
      bold: 'font-bold',
      semibold: 'font-semibold',
      medium: 'font-medium',
    },
    size: {
      xxl: 'text-[3.75rem] leading-[120%] tracking-[-0.01em]',
      xl: 'text-[3rem] tracking-[-0.01em]',
      l: 'text-[2.5rem] tracking-[-0.01em]',
      m: 'text-[2.25rem]',
      s: 'text-[2rem]',
      xs: 'text-[1.75rem]',
    },
  },
  defaultVariants: {
    weight: 'medium',
    size: 'l',
  },
});

export type HeadingTextStylesProps = VariantProps<typeof headingTextStyles>;

const titleTextStyles = tv({
  extend: textContentColors,
  base: 'leading-[132%]',
  variants: {
    weight: {
      bold: 'font-bold',
      semibold: 'font-semibold',
      medium: 'font-medium',
    },
    size: {
      l: 'text-[1.5rem]',
      m: 'text-[1.25rem]',
      s: 'text-[1.13rem]',
    },
  },
  defaultVariants: {
    weight: 'semibold',
    size: 'm',
  },
});

export type TitleTextStylesProps = VariantProps<typeof titleTextStyles>;

const bodyTextStyles = tv({
  extend: textContentColors,
  base: 'leading-[148%] tracking-[-0.01em] subpixel-antialiased',
  variants: {
    weight: {
      bold: 'font-bold',
      semibold: 'font-semibold',
      medium: 'font-medium',
      normal: 'font-normal',
    },
    size: {
      l: 'text-[1rem]',
      m: 'text-[0.88rem]',
      s: 'text-[0.75rem] tracking-normal',
    },
  },
  defaultVariants: {
    weight: 'medium',
    size: 'l',
  },
});

export type BodyTextStylesProps = VariantProps<typeof bodyTextStyles>;

const labelTextStyles = tv({
  extend: textContentColors,
  base: 'leading-[142%] tracking-[0.01em]',
  variants: {
    weight: {
      bold: 'font-bold',
      semibold: 'font-semibold',
      medium: 'font-medium',
    },
    size: {
      l: 'text-[0.69rem]',
      s: 'text-[0.63rem]',
    },
  },
  defaultVariants: {
    weight: 'semibold',
    size: 'l',
  },
});

export type LabelTextStylesProps = VariantProps<typeof labelTextStyles>;

const linkTextStyles = tv({
  extend: textContentColors,
  base: 'leading-[148%] tracking-[-0.01em]',
  variants: {
    weight: {
      semibold: 'font-semibold',
      medium: 'font-medium',
      regular: 'font-normal',
    },
    size: {
      l: 'text-[1rem]',
      m: 'text-[0.88rem]',
      s: 'text-[0.75rem] tracking-normal',
    },
  },
});

export type LinkTextStylesProps = VariantProps<typeof linkTextStyles>;

export const textStyles = {
  heading: headingTextStyles,
  title: titleTextStyles,
  body: bodyTextStyles,
  label: labelTextStyles,
  link: linkTextStyles,
};
