import { tv } from './cn';

export const flexVariants = tv({
  base: 'flex',
  variants: {
    align: {
      start: 'items-start',
      center: 'items-center',
      end: 'items-end',
      stretch: 'items-stretch',
    },
    justify: {
      between: 'justify-between',
      around: 'justify-around',
      center: 'justify-center',
      start: 'justify-start',
      end: 'justify-end',
    },
    wFull: {
      true: 'w-full',
    },
    gap: {
      0: 'gap-0',
      1: 'gap-1',
      2: 'gap-2',
      3: 'gap-3',
      4: 'gap-4',
      5: 'gap-5',
      6: 'gap-6',
      7: 'gap-7',
      8: 'gap-8',
    },
  },
  defaultVariants: {
    gap: 2,
    wFull: true,
  },
});
