import { useQuery } from '@apollo/client';
import { CheckIcon, PlusIcon, UserIcon, UserPlusIcon } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { eluveAdminHasuraContext } from '@eluve/apollo-client';
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Divider,
  P,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantName } from '@eluve/smart-blocks';

import { useAddUserToTenant } from '../hooks/useAddUserToTenant';
import { UserFragment } from '../operations';

const GetUsersQuery = graphql(
  `
    query GetUsers($addedUserIds: [uuid!]!) {
      users(where: { id: { _nin: $addedUserIds } }) {
        ...User
      }
    }
  `,
  [UserFragment],
);

interface AddUserActionProps {
  tenantId: string;
  addedUserIds: string[];
}

export const AddUserAction: React.FC<AddUserActionProps> = ({
  tenantId,
  addedUserIds,
}) => {
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();
  const [open, setOpen] = useState(false);

  const { data, loading } = useQuery(GetUsersQuery, {
    variables: { addedUserIds },
    context: eluveAdminHasuraContext,
  });

  const addUserToTenant = useAddUserToTenant(tenantId);

  const tenantName = useTenantName(tenantId);

  const handleAddUser = () => {
    if (!selectedUserId) return;

    const user = data?.users.find((u) => u.id === selectedUserId);
    const name = user?.displayName ?? `${user?.firstName} ${user?.lastName}`;

    if (!user) return;

    addUserToTenant({
      variables: {
        tenantId,
        userId: selectedUserId,
        role: 'tenant-member',
      },
      onCompleted: () => {
        toast.success(`User ${name} added to tenant ${tenantName}`);
      },
      onError: () => {
        toast.error(`Failed to add user ${name} from tenant ${tenantName}`);
      },
    });

    setOpen(false);
    setSelectedUserId(undefined);
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedUserId(undefined);
  };

  const hasNoUsersToAdd = data && data.users.length === 0;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="secondary">
          <UserPlusIcon className="mr-2 h-5 w-5" />
          Add User
        </Button>
      </DialogTrigger>
      <DialogContent>
        <div className="flex gap-3">
          <UserPlusIcon className="h-8 w-8" />
          <DialogHeader>
            <DialogTitle className="text-gray-11 font-normal tracking-normal">
              Add New user
            </DialogTitle>
            <DialogDescription>
              Tenant: <strong>{tenantName}</strong>
            </DialogDescription>
          </DialogHeader>
        </div>

        <Divider />

        {!hasNoUsersToAdd ? (
          <>
            <P>Search user by email or name to add them to the tenant.</P>
            <Command className="bg-ui-bg-gray/60">
              <CommandInput placeholder="Search user" />
              <Divider className="border-gray-6 my-0" />
              <CommandList>
                <CommandEmpty>
                  {loading ? 'Loading...' : 'No users available to add.'}
                </CommandEmpty>
                <CommandGroup
                  heading="Users"
                  className="h-[220px] overflow-auto"
                >
                  {data?.users.map((user) => {
                    const name =
                      user.displayName ?? `${user.firstName} ${user.lastName}`;
                    return (
                      <CommandItem
                        className="text-primary-foreground aria-selected:bg-ui-bg-active-gray aria-selected:text-foreground data-[checked]:bg-ui-bg-active"
                        key={user.id}
                        value={`${name} ${user.email}`}
                        onSelect={() => setSelectedUserId(user.id)}
                        data-checked={
                          selectedUserId === user.id ? 'true' : undefined
                        }
                      >
                        <div className="flex items-center gap-3">
                          {selectedUserId === user.id ? (
                            <CheckIcon className="h-6 w-6" />
                          ) : (
                            <UserIcon className="h-6 w-6" />
                          )}

                          <P className="text-gray-12 whitespace-pre-line font-normal tracking-normal">
                            {name}
                            <br />
                            <span className="text-gray-10">{user.email}</span>
                          </P>
                        </div>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </CommandList>
            </Command>{' '}
          </>
        ) : (
          <P>No users available to add.</P>
        )}
        <DialogFooter>
          <Button type="button" variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          {!hasNoUsersToAdd && (
            <Button onClick={handleAddUser} type="button">
              <PlusIcon className="mr-2 h-5 w-5" />
              Add
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
