import {
  Box,
  Divider,
  Label,
  SectionCard,
  SectionCardContent,
  SectionCardHeader,
} from '@eluve/components';
import { useAppointmentId } from '@eluve/frontend-appointment-hooks';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { AddToTranscript } from './AddToTranscript';
import { LatestAppointmentTemplate } from './LatestAppointmentTemplate';
import { OpenInAdmin } from './OpenInAdmin';
import { RegenerateSummary } from './RegenerateSummary';
import { SimulateDegradedTranscription } from './SimulateDegradedTranscription';
import { UploadTranscript } from './UploadTranscript';

export const EluveAdminPanel: React.FC = () => {
  const appointmentId = useAppointmentId();
  const tenantId = useTenantIdFromParams();

  return (
    <SectionCard>
      <SectionCardHeader>Eluve Admin Panel</SectionCardHeader>
      <SectionCardContent>
        <Box hStack className="w-full items-start gap-6">
          <Box vStack className="flex-1 items-stretch">
            <AddToTranscript appointmentId={appointmentId} />
            <Divider />
            <UploadTranscript appointmentId={appointmentId} />
            <Divider />
            <LatestAppointmentTemplate appointmentId={appointmentId} />
          </Box>
          <Box vStack className="flex-1 items-stretch">
            <Label>Misc. Actions</Label>
            <OpenInAdmin appointmentId={appointmentId} tenantId={tenantId!} />
            <RegenerateSummary
              appointmentId={appointmentId}
              tenantId={tenantId!}
            />
            <Divider />
            <SimulateDegradedTranscription />
          </Box>
        </Box>
      </SectionCardContent>
    </SectionCard>
  );
};
