import { useEffect, useRef } from 'react';

export const AudioPlayer: React.FC<{ audioUrl?: string }> = ({ audioUrl }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (!audioElement || !audioUrl) return;

    if ('MediaSource' in window) {
      const mediaSource = new MediaSource();
      audioElement.src = URL.createObjectURL(mediaSource);
      let codecs: string;
      let format: string;
      if (audioUrl.includes('webm')) {
        codecs = 'opus';
        format = 'webm';
      } else {
        codecs = 'mp4a.40.2';
        format = 'mp4';
      }

      mediaSource.addEventListener('sourceopen', async () => {
        const sourceBuffer = mediaSource.addSourceBuffer(
          `audio/${format}; codecs="${codecs}"`,
        );
        const response = await fetch(audioUrl);
        const data = await response.arrayBuffer();
        sourceBuffer.appendBuffer(data);

        sourceBuffer.addEventListener('updateend', () => {
          mediaSource.endOfStream();
        });
      });
    }
  }, [audioUrl]);

  return (
    <audio
      ref={audioRef}
      src={audioRef?.current?.src ?? ''}
      controls
      preload="metadata"
    />
  );
};
