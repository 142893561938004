import { useSuspenseQuery } from '@apollo/client';
import { CopyPlusIcon, PlusIcon } from 'lucide-react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { TooltipLabel } from '@eluve/blocks';
import {
  Box,
  Button,
  ColDefBuilder,
  DataTable,
  HStack,
  PageTitle,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { ToggleOutputTemplateActiveAction } from './ToggleOutputTemplateActiveAction';

export interface OutputTemplatesListPageProps {}

const getOutputTemplatesQuery = graphql(`
  query getOutputTemplates {
    llmOutputTemplates(where: { tenantId: { _isNull: true } }) {
      __typename
      id
      name
      description
      llmOutputType
      isActive
      createdAt
    }
  }
`);

type OutputTemplateRow = {
  id: string;
  name: string;
  description: string;
  outputType: string;
  isActive: boolean;
  createdAt: string;
};

const columns = new ColDefBuilder<OutputTemplateRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .defaultSortable('description')
  .defaultSortable('outputType', 'Output Type')
  .defaultBoolean('isActive', 'Active')
  .dateSortable('createdAt', 'Created At')
  .colDef({
    header: 'Actions',
    cell: ({ row }) => (
      <Box hStack gap={1}>
        <TooltipLabel
          label={`Create a new Output Template using '${row.original.name}' as a starting point`}
        >
          <Link to={`./clone/${row.original.id}`}>
            <Button size="icon" variant="outline">
              <CopyPlusIcon />
            </Button>
          </Link>
        </TooltipLabel>
        <ToggleOutputTemplateActiveAction outputTemplateId={row.original.id} />
      </Box>
    ),
  })
  .build();

export const OutputTemplatesListPage: React.FC<
  OutputTemplatesListPageProps
> = () => {
  const {
    data: { llmOutputTemplates },
  } = useSuspenseQuery(getOutputTemplatesQuery);

  const rows = useMemo(() => {
    return llmOutputTemplates.map<OutputTemplateRow>(
      ({ id, isActive, name, description, llmOutputType, createdAt }) => ({
        id,
        name,
        description: description ?? '',
        outputType: llmOutputType,
        isActive,
        createdAt,
      }),
    );
  }, [llmOutputTemplates]);

  return (
    <>
      <HStack justify="between">
        <div className="flex-1">
          <PageTitle>Output Templates</PageTitle>
        </div>
        <Link to="create">
          <Button>
            <PlusIcon className="mr-2 h-5" />
            Create an Output Template
          </Button>
        </Link>
      </HStack>
      <div>
        <DataTable
          columns={columns}
          data={rows}
          enableGlobalSearch
          initialSortingState={[{ id: 'createdAt', desc: true }]}
        />
      </div>
    </>
  );
};
