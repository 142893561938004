import React from 'react';

import { cn } from './cn';

const SectionCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'w-full overflow-y-auto rounded-2xl border border-gray-4 bg-white p-2 text-card-foreground',
      className,
    )}
    {...props}
  />
));

SectionCard.displayName = 'SectionCard';

const SectionCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex h-auto w-full items-center justify-between rounded-t-xl border-b border-gray-4 bg-gray-2 p-2 px-4 tracking-normal',
      'text-lg font-medium tracking-wide',
      className,
    )}
    {...props}
  />
));
SectionCardHeader.displayName = 'SectionCardHeader';

const SectionCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('overflow-y-auto p-4 pb-2', className)}
    {...props}
  />
));

SectionCardContent.displayName = 'SectionCardContent';

export { SectionCard, SectionCardHeader, SectionCardContent };
