import { useMutation } from '@apollo/client';

import { graphql } from '@eluve/graphql.tada';

export const updateAppointmentStatusMutation = graphql(`
  mutation updateAppointmentStatus(
    $tenantId: uuid!
    $appointmentId: uuid!
    $status: AppointmentStatusEnum!
  ) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $appointmentId }
      _set: { status: $status }
    ) {
      __typename
      id
      status
    }
  }
`);

export const useUpdateAppointmentStatus = () => {
  const [updateAppointmentStatus] = useMutation(
    updateAppointmentStatusMutation,
    {
      optimisticResponse: ({ appointmentId, status }) => {
        return {
          updateAppointmentsByPk: {
            __typename: 'Appointments' as const,
            id: appointmentId,
            status: status,
          },
        };
      },
    },
  );

  return updateAppointmentStatus;
};
