import { MatchingRecord } from '@eluve/utils';

import { AppointmentStatusEnum } from './types';

export const AppointmentStatusTypesLookup = {
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED',
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
} as const satisfies MatchingRecord<AppointmentStatusEnum>;
