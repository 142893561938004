import { AlertCircle } from 'lucide-react';
import React from 'react';

import { Box, H2 } from '@eluve/components';

export interface OutputParsingErrorProps {}

export const OutputParsingError: React.FC<OutputParsingErrorProps> = () => {
  return (
    <div className="grid h-128 w-full place-items-center">
      <Box vStack className="items-center p-2">
        <AlertCircle className="size-10 text-orange" />
        <div>
          <H2>There was a problem generating this output</H2>
        </div>
      </Box>
    </div>
  );
};
