import { AlertOctagon, AlertTriangle, BadgeCheck, Info } from 'lucide-react';
import { Toaster as Sonner } from 'sonner';

import { tv } from './cn';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const styles = tv({
  slots: {
    toast:
      'group toast group-[.toaster]:border-border group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:shadow-lg',
    description: 'group-[.toast]:text-muted-foreground',
    actionButton:
      'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
    cancelButton:
      'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
    base: 'group toast group-[.toaster]:border-border group-[.toaster]:shadow-lg',
  },
  variants: {
    status: {
      success: {
        base: 'group-[.toaster]:bg-green group-[.toaster]:text-greenContrast',
      },
      error: {
        base: 'group-[.toaster]:bg-red-200 group-[.toaster]:text-red-800',
      },
      warning: {
        base: 'group-[.toaster]:bg-orange group-[.toaster]:text-orangeContrast',
      },
      info: {
        base: 'group-[.toaster]:bg-teal group-[.toaster]:text-tealContrast',
      },
    },
  },
});

const { actionButton, toast, description, cancelButton, base } = styles();

const SonnerToast = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme={'system'}
      className="group toaster"
      visibleToasts={5}
      icons={{
        success: <BadgeCheck />,
        error: <AlertOctagon className="mr-6" />,
        info: <Info />,
        warning: <AlertTriangle />,
      }}
      toastOptions={{
        classNames: {
          toast: toast(),
          description: description(),
          actionButton: actionButton(),
          cancelButton: cancelButton(),
          success: base({ status: 'success' }),
          error: base({ status: 'error' }),
          warning: base({ status: 'warning' }),
          info: base({ status: 'info' }),
        },
      }}
      {...props}
    />
  );
};

export { SonnerToast };
