import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { AppointmentFileTypesEnum } from './types';

export const AppointmentFileTypesLookup = {
  ADMIN_UPLOAD_AUDIO: 'ADMIN_UPLOAD_AUDIO',
  CHUNKED_AUDIO: 'CHUNKED_AUDIO',
  USER_BACKUP_AUDIO: 'USER_BACKUP_AUDIO',
  WEBSOCKET_AUDIO: 'WEBSOCKET_AUDIO',
  PDF_SUMMARY: 'PDF_SUMMARY',
  RAW_TRANSCRIPT_METADATA: 'RAW_TRANSCRIPT_METADATA',
} as const satisfies MatchingRecord<AppointmentFileTypesEnum>;

export const appointmentFileTypesSchema = z.nativeEnum(
  AppointmentFileTypesLookup,
);
