import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import max from 'date-fns/max';
import { useMemo } from 'react';
import { useUnmount } from 'react-use';
import { create } from 'zustand';

import { useCompleteFragment } from '@eluve/apollo-client';
import { HStack, Icon, textStyles } from '@eluve/components';
import { useAppointmentId } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

const lastUpdatedAtDoctorInteractionNotes = graphql(`
  fragment LastUpdatedDoctorInteractionNotes on AppointmentDoctorInteractions {
    __typename
    updatedAt
  }
`);

const lastUpdatedAtHumanOutputs = graphql(`
  fragment LatestHumanSOAPNote on Appointments @_unmask {
    __typename
    humanOutputs(
      where: { output: { outputType: { _eq: SOAP_NOTE } } }
      limit: 1
      orderBy: { output: { updatedAt: DESC } }
    ) {
      __typename
      appointmentId
      humanOutputId
      output {
        __typename
        id
        updatedAt
      }
    }
  }
`);

export const pendingUnsavedChangesStore = create(() => false);

export const AppointmentLastSaved: React.FC = () => {
  const appointmentId = useAppointmentId();
  const pendingUnsavedChanges = pendingUnsavedChangesStore();

  useUnmount(() => {
    pendingUnsavedChangesStore.setState(false);
  });

  const additionalNotes = useCompleteFragment({
    fragment: lastUpdatedAtDoctorInteractionNotes,
    key: { appointmentId },
    strict: false,
  });

  const summary = useCompleteFragment({
    fragment: lastUpdatedAtHumanOutputs,
    key: { id: appointmentId },
    strict: false,
  });

  const lastSavedAt = useMemo(() => {
    const additionalNotesUpdatedAtDate = additionalNotes?.updatedAt
      ? new Date(additionalNotes.updatedAt)
      : undefined;

    const summaryUpdatedAt = summary?.humanOutputs?.[0]?.output?.updatedAt;

    const summaryUpdatedAtDate = summaryUpdatedAt
      ? new Date(summaryUpdatedAt)
      : undefined;

    const updatedDates = [
      additionalNotesUpdatedAtDate,
      summaryUpdatedAtDate,
    ].filter(Boolean) as Date[];

    return updatedDates.length > 0 ? max(updatedDates) : undefined;
  }, [additionalNotes, summary]);

  const lastSavedDate = lastSavedAt ? (
    <span className={textStyles.body({ color: 'tertiary', size: 'm' })}>
      Last saved {isToday(lastSavedAt) ? 'today' : format(lastSavedAt, 'M/d/y')}{' '}
      at {format(lastSavedAt, 'h:mm a')}
    </span>
  ) : null;

  if (pendingUnsavedChanges) {
    return (
      <HStack wFull>
        {lastSavedDate ?? (
          <span className={textStyles.body({ color: 'tertiary', size: 'm' })}>
            Saving...
          </span>
        )}
        <Icon
          name="Loader2Icon"
          size="xs"
          className="animate-spin text-brand-8"
        />
      </HStack>
    );
  }

  return lastSavedDate;
};
