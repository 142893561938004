import {
  useBackgroundQuery,
  useSubscription,
  useSuspenseQuery,
} from '@apollo/client';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import React, { useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { FCC } from '@eluve/components';
import { useEHRSyncStore } from '@eluve/frontend-feature-ehr-sync';
import {
  getCoreTenantDataQuery,
  listenCoreTenantDataSubscription,
} from '@eluve/frontend-feature-tenants';
import {
  LoggerArgs,
  LoggingContextProvider,
  useExtendedLogger,
} from '@eluve/logger';
import { useUserIdFromSession } from '@eluve/session-helpers';
import {
  TenantStoreProvider,
  getPatientsQuery,
  useEluveExtExists,
  useExternalEhrs,
} from '@eluve/smart-blocks';
import {
  DbProvider,
  UserFileSystemInitializer,
  UserFileSystemStoreProvider,
} from '@eluve/user-local-files';
import { useTenantLocalStorage } from '@eluve/utility-hooks';

import { appConfig } from '../../../../config';
import { initPostMessenger } from '../../utils/post-messenger';

export const TenantProvider: FCC = ({ children }) => {
  const { tenantId } = useParams() as { tenantId: string };

  const tenantLoggerArgs: LoggerArgs = useMemo(
    () => ({ name: 'TenantScoped', metadata: { tenantId } }),
    [tenantId],
  );
  const tenantLogger = useExtendedLogger(tenantLoggerArgs);

  const userId = useUserIdFromSession();
  const externalEhrs = useExternalEhrs(tenantId);
  const { eluveExtExists } = useEluveExtExists();

  const tenantData = useSuspenseQuery(getCoreTenantDataQuery, {
    variables: {
      tenantId: tenantId,
    },
  });

  // Initialize the fetching of the patients data but don't block here
  const [patientsQueryRef, { refetch }] = useBackgroundQuery(getPatientsQuery, {
    // We are manually taking care of refetching so we don't want to do SWR
    // and trigger more requests to get patients than necessary.
    fetchPolicy: 'cache-first',
    variables: {
      tenantId,
    },
  });

  const lastSuccessfulEhrSyncAt = useEHRSyncStore(
    (s) => s.lastSuccessfulEhrSyncAt,
  );

  useEffect(
    function refetchPatientsOnEhrSync() {
      if (lastSuccessfulEhrSyncAt) {
        refetch();
      }
    },
    [lastSuccessfulEhrSyncAt, refetch],
  );

  useSubscription(listenCoreTenantDataSubscription, {
    variables: {
      tenantId: tenantId,
    },
  });

  useEffect(() => {
    if (
      eluveExtExists &&
      externalEhrs?.length &&
      tenantData?.data?.tenantsByPk
    ) {
      initPostMessenger(
        externalEhrs.map(({ domain, vendor }) => ({
          domain,
          vendor,
        })),
        tenantId,
        tenantData.data.tenantsByPk.name,
      );
    }
  }, [eluveExtExists, externalEhrs, tenantData, tenantId]);

  const [advancedMonitoring] = useTenantLocalStorage({
    tenantId,
    key: 'ADVANCED_MONITORING',
    initialValue: false,
  });

  useEffect(
    function updateDatadogRumUserDetails() {
      (async () => {
        if (userId && tenantId) {
          let trackingConsent: RumInitConfiguration['trackingConsent'] =
            'not-granted';
          const isSiloamProd = appConfig.VITE_API_DOMAIN.includes('siloam');
          if (isSiloamProd) {
            trackingConsent = 'granted';
          } else {
            trackingConsent = advancedMonitoring ? 'granted' : 'not-granted';
          }

          datadogRum.setTrackingConsent(trackingConsent);
          datadogRum.setUser({
            id: userId,
            tenantId,
          });
        }
      })();
    },
    [userId, tenantId, advancedMonitoring],
  );

  if (tenantData.data.tenantsByPk?.isPendingApproval) {
    return <Navigate to={`/tenants/pending-approval`} />;
  }

  return (
    <UserFileSystemStoreProvider
      initializer={() => ({
        userId,
        tenantId,
        userFileSystem: {
          type: 'pending',
        },
      })}
    >
      <LoggingContextProvider loggerInput={tenantLogger}>
        <DbProvider userId={userId} tenantId={tenantId}>
          <TenantStoreProvider
            initializer={() => ({
              patientsQueryRef,
            })}
          >
            <UserFileSystemInitializer>{children}</UserFileSystemInitializer>
          </TenantStoreProvider>
        </DbProvider>
      </LoggingContextProvider>
    </UserFileSystemStoreProvider>
  );
};
