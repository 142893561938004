import React from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { useCompleteFragment } from '@eluve/apollo-client';
import { VStack } from '@eluve/components';
import { useAppointmentContext } from '@eluve/frontend-appointment-hooks';
import {
  AppointmentLanguageSelector,
  PendingTranscriptionLoader,
} from '@eluve/frontend-feature-appointment';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

import {
  useTranscriptionMachineActor,
  useTranscriptionMachineSelector,
} from '../machines/transcription/TranscriptionMachineProvider';

import { AppointmentDetailsFragment } from './AppointmentDetails';
import { AppointmentInSessionTranscript } from './AppointmentInSessionTranscript';
import { UploadingFileLoader } from './UploadingFileLoader';

export const AppointmentTranscription: React.FC = () => {
  const { appointmentId, tenantId } = useAppointmentContext();
  const isCurrentAppointmentInSession = useTranscriptionMachineSelector(
    (s) =>
      s.matches({ InSession: 'Recording' }) &&
      s.context.sessionAppointmentId === appointmentId,
  );

  const { send } = useTranscriptionMachineActor();
  const apiClient = useApiClient();

  const appointmentDetails = useCompleteFragment({
    fragment: AppointmentDetailsFragment,
    key: {
      id: appointmentId,
    },
  });

  return (
    <>
      <VStack className="border-gray-5 rounded-lg border p-5">
        <VStack
          wFull
          gap={3}
          justify="between"
          className="items-start md:flex-row md:items-center"
        >
          <div>
            <FeatureFlaggedComponent flag="MULTILINGUAL_SUPPORT">
              <AppointmentLanguageSelector
                inputLanguage
                onInputLanguageChange={(newLanguage) => {
                  if (appointmentDetails.inputLanguage === newLanguage) {
                    return;
                  }
                  if (isCurrentAppointmentInSession) {
                    send({ type: 'RECONNECT' });
                  }
                  apiClient.transcription.batchTranscribe({
                    params: {
                      tenantId,
                    },
                    body: {
                      appointmentId,
                    },
                  });
                }}
              />
            </FeatureFlaggedComponent>
          </div>
        </VStack>

        <AppointmentInSessionTranscript
          inSession={isCurrentAppointmentInSession}
        />
      </VStack>

      {!isCurrentAppointmentInSession && (
        <>
          <PendingTranscriptionLoader
            tenantId={tenantId}
            appointmentId={appointmentId}
          />
          <UploadingFileLoader appointmentId={appointmentId} />
        </>
      )}
    </>
  );
};
