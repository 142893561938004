import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect } from 'react';

type ConfigContract = {
  ADVANCED_MONITORING: boolean;
};
type ConfigKeys = keyof ConfigContract;

type StoredValue<T extends ConfigKeys> = {
  value: ConfigContract[T];
  expiry: number | null;
};

type LocalStorageOptions<T extends ConfigKeys> = {
  key: T;
  initialValue: ConfigContract[T];
  ttlInSeconds?: number;
  tenantId: string;
};

export const useTenantLocalStorage = <T extends ConfigKeys>({
  key,
  initialValue,
  ttlInSeconds,
  tenantId,
}: LocalStorageOptions<T>): [
  ConfigContract[T],
  (value: ConfigContract[T]) => void,
] => {
  const [storedValue, setStoredValue] = useLocalStorage<StoredValue<T> | null>(
    getKey({ key, tenantId }),
    null,
  );

  useEffect(() => {
    if (storedValue) {
      const parsedItem = storedValue as StoredValue<T>;
      if (parsedItem.expiry && Date.now() > parsedItem.expiry) {
        setStoredValue(null);
      }
    }
  }, [storedValue, setStoredValue]);

  const setValue = (value: ConfigContract[T]) => {
    const expiry = ttlInSeconds ? Date.now() + ttlInSeconds * 1000 : null;
    const item: StoredValue<T> = { value, expiry };
    setStoredValue(item);
  };

  const value: ConfigContract[T] = storedValue?.value ?? initialValue;

  return [value, setValue];
};

export const getTenantValueFromLocalStorage = async <T extends ConfigKeys>({
  key,
  tenantId,
}: {
  key: T;
  tenantId: string;
}): Promise<ConfigContract[T] | null> => {
  const storedValue = localStorage.getItem(getKey({ key, tenantId }));
  if (storedValue) {
    try {
      const parsedItem = JSON.parse(storedValue) as StoredValue<T>;
      if (!parsedItem.expiry || Date.now() <= parsedItem.expiry) {
        return parsedItem.value;
      } else {
        localStorage.removeItem(`${key}-${tenantId}`);
      }
    } catch (error) {
      // ignore error
    }
  }
  return null;
};

const getKey = <T extends ConfigKeys>({
  key,
  tenantId,
}: {
  tenantId: string;
  key: T;
}) => [tenantId, key].join('-');
