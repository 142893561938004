import { Link } from 'react-router-dom';

import { Button, Icon } from '@eluve/components';

type OpenInAdminProps = {
  appointmentId: string;
  tenantId: string;
};

export const OpenInAdmin: React.FC<OpenInAdminProps> = ({
  appointmentId,
  tenantId,
}) => {
  const url = `/admin/tenants/${tenantId}/appointments/${appointmentId}/details`;

  return (
    <Button asChild className="w-fit" variant="outline">
      <Link to={url}>
        <Icon className="mr-2" name="ShieldCheck" size="xs" />
        Open in Admin Dashboard
      </Link>
    </Button>
  );
};
