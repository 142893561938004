import React, { useState } from 'react';

import { TooltipLabel } from '@eluve/blocks';
import { Box, Switch } from '@eluve/components';
import { useSession } from '@eluve/session-helpers';
import { EluveAdminOnly } from '@eluve/smart-blocks';

import { AppointmentFilesDrawer } from './AppointmentFilesDrawer';
import { EluveAdminPanel } from './components/EluveAdminPanel/EluveAdminPanel';

export const EluveAdminControls: React.FC = () => {
  const { isEluveAdmin } = useSession();

  const [advancedMode, setAdvancedMode] = useState<boolean>(false);

  return (
    <>
      <EluveAdminOnly>
        <Box hStack className="gap-4">
          <AppointmentFilesDrawer />
          <TooltipLabel label="Admin controls">
            <div>
              <Switch
                checked={advancedMode}
                onCheckedChange={(checked: boolean) => {
                  setAdvancedMode(checked);
                }}
              />
            </div>
          </TooltipLabel>
        </Box>
      </EluveAdminOnly>

      {isEluveAdmin && advancedMode && <EluveAdminPanel />}
    </>
  );
};
