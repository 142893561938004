import { CubeIcon } from '@radix-ui/react-icons';
import { ChevronDown, FileCodeIcon, TriangleAlertIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { CopyTextButton } from '@eluve/blocks';
import { TooltipLabel } from '@eluve/blocks';
import {
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Divider,
  Label,
  textStyles,
} from '@eluve/components';
import { latestHumanSOAPNoteWithPromptTemplateFragment } from '@eluve/frontend-appointment-hooks';

interface LatestAppointmentTemplateProps {
  appointmentId: string;
  handleModelInfoUpdate?: (info: string) => void;
}

const LatestAppointmentTemplateContent: React.FC<
  LatestAppointmentTemplateProps
> = ({ appointmentId, handleModelInfoUpdate }) => {
  const appointment = useCompleteFragment({
    fragment: latestHumanSOAPNoteWithPromptTemplateFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  const output = appointment?.humanOutputs[0]?.output?.llm_output;

  useEffect(() => {
    const promptTemplate = output?.modelPromptTemplate;

    if (!promptTemplate || !output) return;
    const { args, id, modelType } = promptTemplate.model_args;

    handleModelInfoUpdate?.(
      JSON.stringify(
        {
          modelArgs: {
            id,
            modelType,
            ...args,
          },
          llmOutputTemplate: {
            id: output.outputTemplate?.id,
            name: output.outputTemplate?.name,
          },
          promptTemplate: {
            id: output.modelPromptTemplate.template.id,
            name: output.modelPromptTemplate.template.name,
          },
        },
        null,
        2,
      ),
    );
  }, [output, handleModelInfoUpdate]);

  if (!output) {
    return (
      <CollapsibleContent>
        <div className="flex w-full gap-3 rounded-lg border border-amber-300 bg-amber-50 p-3 text-sm text-amber-500">
          <TriangleAlertIcon className="h-5 w-5" />
          No summary generated for this appointment.
        </div>
      </CollapsibleContent>
    );
  }

  const {
    template,
    model_args: { args: modelArgs, modelType },
  } = output.modelPromptTemplate;

  const llmOutputTemplate = output.outputTemplate;

  return (
    <CollapsibleContent>
      <div className="text-gray-11 border-ui-border-gray flex flex-col gap-0 rounded-lg border text-sm">
        <div className="flex flex-1 items-center gap-2 p-2">
          <TooltipLabel label="Prompt Template">
            <FileCodeIcon className="mx-1 h-6 w-6" />
          </TooltipLabel>
          <div>{template.name}</div>
        </div>
        {llmOutputTemplate && (
          <div className="flex flex-1 items-center gap-2 p-2">
            <TooltipLabel label="Output Template">
              <FileCodeIcon className="mx-1 h-6 w-6" />
            </TooltipLabel>
            <div>{llmOutputTemplate.name}</div>
          </div>
        )}
        {modelArgs && (
          <>
            <Divider className="ml-12" />
            <div className="flex flex-1 items-start gap-2 p-2 ">
              <TooltipLabel label="Model">
                <CubeIcon className="mx-1 h-6 w-6" />
              </TooltipLabel>
              <div className="flex flex-col space-y-1">
                <p>{modelType}</p>
                <div>
                  Temperature: <b>{modelArgs.temperature}</b>
                </div>
                <div>
                  Version: <b>{modelArgs.model}</b>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </CollapsibleContent>
  );
};

export const LatestAppointmentTemplate: React.FC<
  LatestAppointmentTemplateProps
> = ({ appointmentId }) => {
  const [modalInfo, setModalInfo] = useState<string | null>(null);
  const [opened, setOpened] = useState(false);

  return (
    <Collapsible open={opened} onOpenChange={setOpened}>
      <div className="space-y-2">
        <Box hStack>
          <Label className="px-1">Latest Summary Info</Label>
          <CopyTextButton
            disabled={!modalInfo}
            copyText={modalInfo ?? ''}
            hideContentInTooltip
          />
          <CollapsibleTrigger>
            <Box hStack>
              <ChevronDown
                className='size-5 transition-transform duration-300 ease-in-out data-[state="open"]:-rotate-180'
                data-state={opened ? 'open' : 'closed'}
              />
              <p className={textStyles.body({ size: 'm' })}>
                {!opened ? 'Show' : 'Hide'}
              </p>
            </Box>
          </CollapsibleTrigger>
        </Box>
        <LatestAppointmentTemplateContent
          appointmentId={appointmentId}
          handleModelInfoUpdate={setModalInfo}
        />
      </div>
    </Collapsible>
  );
};
