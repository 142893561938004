import { Button, Icon } from '@eluve/components';

import { useSummarizeAppointment } from '../../hooks/useSummarizeAppointment';

interface RegenerateSummaryProps {
  disabled?: boolean;
  tenantId: string;
  appointmentId: string;
}

export const RegenerateSummary: React.FC<RegenerateSummaryProps> = ({
  disabled,
  tenantId,
  appointmentId,
}) => {
  const regenerateSummary = useSummarizeAppointment();

  return (
    <Button
      type="button"
      variant="outline"
      className="w-fit"
      disabled={disabled}
      onClick={() => regenerateSummary(tenantId, appointmentId)}
    >
      <Icon className="mr-2" name="RotateCw" size="xs" />
      Regenerate Summary
    </Button>
  );
};
