import some from 'lodash/some';
import {
  CircleCheckIcon,
  CircleHelpIcon,
  RefreshCwIcon,
  TriangleAlertIcon,
} from 'lucide-react';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useApiClient } from '@eluve/api-client-provider';
import { cacheUtils } from '@eluve/apollo-client';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  EluveNameLogo,
  H1,
  P,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@eluve/components';
import { locationUsersFragment } from '@eluve/frontend-feature-location-hierarchy';
import { useCaptureEventOnMount } from '@eluve/posthog-react';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import { useIsFeatureFlagEnabled, useTenantName } from '@eluve/smart-blocks';
import { getVendorProvider } from '@eluve/vendor';

import { appConfig } from '../../../../../config';
import { useFirstAvailableEhr } from '../hooks/useFirstAvailableEhr';
import { useTenantLocations } from '../hooks/useTenantLocations';
import { useUserLocations } from '../hooks/useUserLocation';
import { useEhrStatusDetails } from '../useEhrStatusDetails';
import { useImportDataFromEhr } from '../useImportDataFromEhr';

const ImportedAppointmentsPrompt: React.FC = () => {
  const [_, setSearchParams] = useSearchParams();
  return (
    <div>
      <div className="pt-24">
        <div className="flex flex-row items-center">
          <CircleCheckIcon
            size={30}
            className="mr-1 border-none text-white"
            fill=""
          />
          <H1>You're all set!</H1>
        </div>
        <div className="mt-4">
          <span className="text-center text-sm">
            Your EHR integration is all set up! You can now see your upcoming
            appointments on the homepage.
          </span>
        </div>

        <div className="pt-10">
          <Button
            variant="outline"
            className="w-full"
            onClick={() => {
              setSearchParams({ event: '' });
            }}
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

const ImportingAppointmentsPrompt: React.FC = () => {
  const firstAvailableEhr = useFirstAvailableEhr();
  if (!firstAvailableEhr) {
    return null;
  }
  const vendorProvider = getVendorProvider(firstAvailableEhr.vendor);
  const logoData = vendorProvider.getLogo();
  const logo = <img src={logoData} alt="Logo" className="w-20" />;
  return (
    <div>
      <div className="mb-10 flex flex-col items-center">{logo}</div>
      <div>
        <H1>Importing appointments</H1>
        <div className="mt-2">
          <span className="text-sm">
            It takes a few seconds for the first import, but afterwards we will
            automatically sync your EHR.
          </span>
        </div>
        <div className="flex flex-col items-center">
          <div className="flex flex-row items-center pb-6 pt-6">
            <RefreshCwIcon className={`mr-2 h-5 animate-spin`} size={14} />
            <span className="text-sm">Importing...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const OnboardingEhrImport: React.FC = () => {
  const tenantId = useTenantIdFromParams();
  const firstAvailableEhr = useFirstAvailableEhr();
  const {
    importDataFromEhr,
    isDataImporting,
    isImportAllowed,
    isSupportedBrowser,
    importResults,
  } = useImportDataFromEhr(tenantId!);
  let logo: JSX.Element | null = null;
  if (firstAvailableEhr) {
    const vendorProvider = getVendorProvider(firstAvailableEhr.vendor);
    const logoData = vendorProvider.getLogo();
    logo = <img src={logoData} alt="Logo" className="w-20" />;
  }

  useCaptureEventOnMount('ehr_onboarding_import_onboarding_shown');

  const importAppointmentsPrompt = (
    <div>
      <div className="mb-10 flex flex-col items-center">{logo}</div>
      <div>
        <H1>Import appointments</H1>
        <div className="mt-2">
          <span className="text-sm">
            Import EHR data so that Eluve can work seamlessly with your workflow
          </span>
        </div>
        {importResults?.error ? (
          <div className="mb-3 mt-3 flex flex-row self-center bg-amber-50 p-1">
            <P className="font-light md:text-xs">{importResults.error}</P>
          </div>
        ) : (
          <div className="p-4"></div>
        )}
        <Button
          variant="gray"
          className="w-full"
          onClick={() => {
            importDataFromEhr({ showToast: false });
          }}
          disabled={!isImportAllowed && !isSupportedBrowser}
        >
          Start Import
        </Button>
        <div
          className="mt-8 flex h-11 cursor-pointer flex-row items-center bg-brandGray100 p-3"
          onClick={() => {
            window.open(
              'https://eluveinc.notion.site/Data-Protection-Policies-c9deaab9493048bc96c1fe62adef31a9',
              '_blank',
              'noopener,noreferrer',
            );
          }}
        >
          <CircleHelpIcon size={14} className="mr-2" />
          <span className="text-xs font-light">
            Learn how Eluve keeps your data safe
          </span>
        </div>
      </div>
    </div>
  );

  return match({ isDataImporting, importResults })
    .with({ isDataImporting: true }, () => <ImportingAppointmentsPrompt />)
    .with({ importResults: { ok: true } }, () => <ImportedAppointmentsPrompt />)
    .otherwise(() => importAppointmentsPrompt);
};

const OnboardingLocation: React.FC = () => {
  const [newLocation, setNewLocation] = useState<string | null>(null);
  const tenantId = useTenantIdFromParams();
  const userId = useUserIdFromSession();
  const tenantName = useTenantName(tenantId!);
  const { locations } = useTenantLocations();
  const apiClient = useApiClient();

  useCaptureEventOnMount('ehr_onboarding_location_onboarding_shown');

  const defaultLocation = locations.length === 1 ? locations[0]!.id : undefined;
  const newLocationId = newLocation ?? defaultLocation;

  const addUserToLocation = async () => {
    await apiClient.locations.addUserToLocation({
      params: {
        locationId: newLocationId!,
        tenantId: tenantId!,
      },
    });
    cacheUtils.updateFragment(
      {
        fragment: locationUsersFragment,
        key: {
          id: newLocationId!,
        },
      },
      (existing) => {
        if (!existing) {
          return existing;
        }
        const newUser = {
          __typename: 'LocationUsers' as const,
          userId,
          locationId: newLocationId!,
          user: {
            __typename: 'Users' as const,
            id: userId,
            email: '',
            firstName: '',
            lastName: '',
          },
        };
        return {
          ...existing,
          location_users: [...existing.location_users, newUser],
        };
      },
    );
  };

  const placeholder = `Select your ${tenantName ?? ''} location`;
  return (
    <div>
      <H1>Extension installed!</H1>
      <div>
        <span className="text-sm">Connect your EHR account with Eluve</span>
      </div>
      <div className="pb-8 pt-8">
        <Select onValueChange={setNewLocation} value={defaultLocation}>
          <SelectTrigger>
            <SelectValue placeholder={placeholder} />
          </SelectTrigger>
          <SelectContent>
            {!locations.length && (
              <Box
                vStack
                center
                fullWidth
                className="bg-amber-100 p-4 text-amber-600"
              >
                <TriangleAlertIcon size={24} />
                No locations found
                <span className="text-sm">
                  Please contact tenant admin to setup a location
                </span>
              </Box>
            )}
            {locations.map((location) => {
              return (
                <SelectItem
                  key={location.id}
                  className="pl-7 focus:bg-brand-4"
                  value={location.id}
                >
                  {location.name}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>
      <Button
        variant="gray"
        className="w-full"
        onClick={async () => {
          await addUserToLocation();
        }}
        disabled={!newLocationId}
      >
        Next
      </Button>
    </div>
  );
};

export const EhrOnboarding: React.FC = () => {
  const isEhrOnboardingEnabled = useIsFeatureFlagEnabled('EHR_ONBOARDING');
  const tenantId = useTenantIdFromParams();
  const { isImportAllowed, isSupportedBrowser } = useImportDataFromEhr(
    tenantId!,
  );
  const { data: ehrStatus } = useEhrStatusDetails();
  const { isUserPartOfAnyLocation } = useUserLocations();
  const currentTenantEhrStatus =
    ehrStatus?.eluveDomains?.[appConfig.VITE_API_DOMAIN]?.tenants?.[
      tenantId ?? ''
    ];

  let hasSyncedBefore = some(currentTenantEhrStatus?.vendors, (vendor) =>
    some(vendor?.domains, (domain) => Boolean(domain.syncedAt)),
  );
  const hasUserImportedBefore = useRef(hasSyncedBefore);
  if (hasSyncedBefore && !hasUserImportedBefore.current) {
    hasSyncedBefore = false;
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const isUserOnboarding = searchParams.get('event') === 'onboarding';

  return match({
    isEhrOnboardingEnabled,
    isUserOnboarding,
    isUserPartOfAnyLocation,
    hasSyncedBefore,
    isImportAllowed,
    isSupportedBrowser,
  })
    .with(
      {
        isEhrOnboardingEnabled: false,
      },
      () => {
        return null;
      },
    )
    .with(
      {
        isUserOnboarding: false,
        isImportAllowed: false,
        isSupportedBrowser: false,
      },
      () => {
        return null;
      },
    )
    .with({ isUserPartOfAnyLocation: false }, () => {
      return (
        <Dialog
          open={isUserOnboarding}
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setSearchParams({ event: '' });
            }
          }}
        >
          <DialogContent className="flex max-w-xl flex-col gap-0 p-6">
            <DialogHeader>
              <DialogTitle>
                <EluveNameLogo height={30} />
              </DialogTitle>
            </DialogHeader>
            <div className="h-128 w-full items-center pb-24 pl-24 pr-24 pt-32">
              <OnboardingLocation />
            </div>
          </DialogContent>
        </Dialog>
      );
    })
    .with({ hasSyncedBefore: false }, () => {
      return (
        <Dialog
          open={isUserOnboarding}
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setSearchParams({ event: '' });
            }
          }}
        >
          <DialogContent className="flex max-w-xl flex-col gap-0 p-6">
            <DialogHeader>
              <DialogTitle>
                <EluveNameLogo height={30} />
              </DialogTitle>
            </DialogHeader>
            <div className="h-128 w-full self-center pb-20 pl-24 pr-24 pt-10">
              <OnboardingEhrImport />
            </div>
          </DialogContent>
        </Dialog>
      );
    })
    .otherwise(() => null);
};
