import { useSuspenseQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { eluveAdminHasuraContext } from '@eluve/apollo-client';
import {
  DynamicArtifactTemplateBuilder,
  templateBuilderStore,
} from '@eluve/blocks';
import { HStack, NewButton, PageTitle } from '@eluve/components';
import { DynamicArtifactTemplate } from '@eluve/llm-outputs';
import { dynamicArtifactTemplateSchema } from '@eluve/llm-outputs';
import { useSession } from '@eluve/session-helpers';

import { OutputTemplatesTester } from './OutputTemplatesTester';
import {
  getOutputTemplateQuery,
  updateOutputTemplateMutation,
} from './operations';

export const OutputTemplateDetailsPage: React.FC<{
  showJsonSchema?: boolean;
}> = ({ showJsonSchema = true }) => {
  const { outputTemplateId } = useParams() as { outputTemplateId: string };
  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const {
    data: { llmOutputTemplatesByPk: template },
  } = useSuspenseQuery(getOutputTemplateQuery, {
    variables: {
      id: outputTemplateId,
    },
  });

  const artifactTemplate = useMemo(() => {
    const parsed = dynamicArtifactTemplateSchema.safeParse(
      template?.dynamicArtifactTemplate,
    );
    if (!parsed.success) {
      return undefined;
    }

    return parsed.data;
  }, [template?.dynamicArtifactTemplate]);

  const [updateOutputTemplate] = useMutation(updateOutputTemplateMutation, {
    onCompleted: () => {
      toast.success('Output template updated');
    },
    onError: () => toast.error('Failed to update output template'),
    optimisticResponse: (data) => ({
      updateLlmOutputTemplatesByPk: {
        ...data,
        dynamicArtifactTemplate: data.dynamicArtifactTemplate.blocks,
        __typename: 'LlmOutputTemplates' as const,
        isActive: true,
        llmOutputType: 'SOAP_NOTE' as const,
      },
    }),
  });

  useEffect(() => {
    if (artifactTemplate) {
      templateBuilderStore.send({
        type: 'hydrateArtifact',
        artifactTemplate,
        outputType: template?.llmOutputType ?? 'SOAP_NOTE',
        isImported: Boolean(template?.external_chart_templates?.length),
      });
    }
  }, [artifactTemplate, template?.llmOutputType]);

  const { isEluveAdmin } = useSession();

  const handleSave = async () => {
    setIsEditing(false);
    const { context } = templateBuilderStore.getSnapshot();
    const dynamicArtifact: DynamicArtifactTemplate = {
      name: context.name,
      description: context.description,
      blocks: context.blocks,
    };
    await updateOutputTemplate({
      context: eluveAdminHasuraContext,
      variables: {
        id: outputTemplateId,
        name: context.name,
        description: context.description,
        dynamicArtifactTemplate: dynamicArtifact,
      },
    });
  };

  return (
    <>
      <HStack gap={4}>
        <PageTitle cName="flex-1">
          {artifactTemplate?.name ?? template?.name}
        </PageTitle>
      </HStack>
      {isEluveAdmin && (
        <HStack>
          <NewButton
            type="primary"
            disabled={isEditing}
            onClick={() => setIsEditing(true)}
            text="Edit"
          />
          <NewButton
            type="primary"
            disabled={!isValid || !isEditing}
            onClick={handleSave}
            text="Save"
          />
          {isEditing && <OutputTemplatesTester />}
        </HStack>
      )}
      <DynamicArtifactTemplateBuilder
        onIsValidChange={setIsValid}
        showJsonSchema={showJsonSchema}
        isReadonly={!isEditing}
      />
    </>
  );
};
