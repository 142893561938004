import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Session from 'supertokens-auth-react/recipe/session';

import { useApiClient } from '@eluve/api-client-provider';
import { Button } from '@eluve/components';
import { getCoreTenantDataQuery } from '@eluve/frontend-feature-tenants';
import { useTenantIdFromSession } from '@eluve/session-helpers';

export const PendingApprovalPage: React.FC = () => {
  const apiClient = useApiClient();
  const tenantId = useTenantIdFromSession();
  const [query] = useLazyQuery(getCoreTenantDataQuery, {
    variables: {
      tenantId: tenantId!,
    },
  });

  const navigate = useNavigate();

  const handleRetry = async () => {
    await apiClient.auth.refreshAllowedTenants();
    if (tenantId) {
      const { data } = await query();
      if ((data?.tenantsByPk?.isPendingApproval ?? true) === false) {
        navigate('/');
      }
    }
  };

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-brandGray900 sm:text-5xl">
          Account Pending
        </h1>
        <p className="mt-6 text-base leading-7 text-brandGray600">
          Thanks for your interest in Eluve! Your account is pending review and
          should be enabled shortly.
        </p>
        <div className="mt-6 flex items-center justify-center gap-x-2">
          <Button onClick={handleRetry}>Try Again</Button>
          <Button
            variant={'outline'}
            onClick={async () => {
              await Session.signOut();
              navigate('/login');
            }}
          >
            Sign Out
          </Button>
        </div>
      </div>
    </main>
  );
};
