import sortBy from 'lodash/sortBy';

import { useCompleteFragment } from '@eluve/apollo-client';

import { useAppointmentId } from './appointment.context';
import { appointmentTranscriptAndSegmentsFragment } from './operations';

/**
 * Gives convenient access to the full concatenated transcript for an appointment
 * by reading it from the apollo cache
 */
export const useAppointmentTranscript = () => {
  const id = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentTranscriptAndSegmentsFragment,
    key: {
      id,
    },
    strict: false,
  });

  const segments = sortBy(
    (data?.segments ?? []).map((s) => ({
      transcript: s.transcript ?? '',
      startedAt: s.recordingStartedAt,
      pausedAt: s.recordingDuration
        ? new Date(
            new Date(s.recordingStartedAt).getTime() + s.recordingDuration,
          ).toISOString()
        : undefined,
    })),
    (s) => s.startedAt,
  );

  return {
    transcript: data?.transcription?.transcript ?? '',
    segments,
  };
};
