import { cacheUtils } from '@eluve/apollo-client';
import { appointmentTranscriptFragment } from '@eluve/frontend-appointment-hooks';

export const getCurrentTranscript = (
  appointmentId: string,
): {
  transcript: string;
  segments?: { transcript: string; startedAt: string }[];
} => {
  const appointment = cacheUtils.readFragment({
    fragment: appointmentTranscriptFragment,
    key: {
      id: appointmentId,
    },
    strict: false,
  });

  return {
    transcript: appointment?.transcription?.transcript ?? '',
  };
};
